<template>
    <button
        :aria-label="`option-${option.name}`"
        class="relative btn btn-ghost flex-1 min-w-content overflow-hidden"
        :class="[
            {
                'border-dark-blue border-2': isOptionSelected,
                'border-beige/50': !isOptionSelected,
                'btn-size-xxs md:btn-size-xs': !optionButtonDefault,
                'opacity-50 text-medium-grey': isOptionNotAvailable || isOptionNotCombinable,
            },
        ]"
    >
        <span :id="`${option.id}-choice-label`" data-testid="product-variant-text">
            {{ option.name }}
        </span>
    </button>
</template>

<script setup lang="ts">
const props = defineProps(['option', 'optionButtonDefault']);

const { product } = useProduct();
const isOptionNotCombinable = computed((): boolean => {
    // @ts-ignore
    return props.option?.extensions?.combinable?.combinable === false;
});

const isOptionNotAvailable = computed((): boolean => {
    return !product.value.available && isOptionSelected.value;
});

const isOptionSelected = computed(() => product.value?.optionIds?.includes(props.option.id));
</script>
