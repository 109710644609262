<template>
    <AccountGrid>
        <div class="flex flex-col gap-4 xl:gap-12">
            <div class="w-full flex flex-col gap-4">
                <h2 class="text-lg xl:text-4xl font-medium text-dark-blue">
                    <CommonLabel path="BWF/shop/account/subscriptions/headline"></CommonLabel>
                </h2>
                <CommonLabel tag="p" path="BWF/shop/account/subscriptions/description"></CommonLabel>
            </div>
            <div class="flex flex-col gap-4" :class="{ 'animate-pulse': isLoading }">
                <LoadingElement v-if="isLoading" class="aspect-3/2"></LoadingElement>
                <template v-else>
                    <EditableArea :content="paymentExpirationWarningArea" :custom-view="EmptyArea">
                        <div v-if="isEditMode() || paymentExpirationWarning" class="p-4 bg-red-400 rounded-lg">
                            <div class="font-medium">
                                {{ paymentExpirationWarningArea?.paymentExpirationHeadline }}
                            </div>
                            <div
                                class="prose hyphens-auto max-w-none"
                                v-html="paymentExpirationWarningArea?.paymentExpirationDescription"
                            ></div>
                        </div>
                    </EditableArea>

                    <SubscriptionContract v-if="isEditMode()"></SubscriptionContract>
                    <SubscriptionContract
                        v-for="(contract, index) in userContracts?.elements"
                        :key="`contract-${index}`"
                        :contract="contract"
                        @contract-update="loadContracts"
                        @contract-cancel="contractCancel(contract.id, 'exchangebox')"
                        @subscription-contract-cancel="contractCancel(contract.id, 'subscription')"
                        @contract-change-payment="changePayment(contract.id)"
                    >
                    </SubscriptionContract>
                </template>
                <EditableArea
                    v-if="(!isLoading && userContracts?.elements?.length === 0) || isEditMode()"
                    :content="empty"
                ></EditableArea>
            </div>

            <EditableArea
                v-if="informationArea?.depositHeadline || informationArea?.depositDescription || isEditMode()"
                :content="informationArea"
                :custom-view="EmptyArea"
            >
                <div class="w-full flex flex-col gap-4 border border-grey rounded-lg px-4 py-6 xl:px-6">
                    <div class="text-xl font-medium">{{ informationArea?.depositHeadline }}</div>
                    <div class="prose hyphens-auto max-w-none" v-html="informationArea?.depositDescription"></div>
                </div>
            </EditableArea>

            <div class="w-full flex flex-col gap-4 border border-grey rounded-lg px-4 py-6 xl:px-6">
                <CommonLabel
                    tag="p"
                    class="font-medium text-xl"
                    path="BWF/shop/account/subscriptions/depositTitle"
                ></CommonLabel>
                <SharedPrice :value="totalDeposit"></SharedPrice>
            </div>
        </div>
        <EditableArea :content="cancellationModalArea" :custom-view="EmptyArea">
            <ModalComponent :controller="cancellationModal" :content="cancellationModalArea" :text-centered="true">
                <div class="flex flex-col gap-5 mx-auto">
                    <button class="btn btn-blue w-full" @click="onCancelSubscription()">
                        <CommonLabel path="BWF/shop/account/subscriptions/modal/confirmCancellation" />
                    </button>
                    <button class="text-link w-full" @click="cancellationModal.close()">
                        <CommonLabel path="BWF/shop/account/subscriptions/modal/abortCancellation" />
                    </button>
                </div>
            </ModalComponent>
        </EditableArea>

        <EditableArea :content="subscriptionCancellationModalArea" :custom-view="EmptyArea">
            <ModalComponent
                :controller="subscriptionCancellationModal"
                :content="subscriptionCancellationModalArea"
                :text-centered="true"
            >
                <div class="flex flex-col gap-5 mx-auto">
                    <button class="btn btn-blue w-full" @click="onCancelSubscription()">
                        <CommonLabel path="BWF/shop/account/subscriptions/modal/confirmSubscriptionCancellation" />
                    </button>
                    <button class="text-link w-full" @click="subscriptionCancellationModal.close()">
                        <CommonLabel path="BWF/shop/account/subscriptions/modal/abortCancellation" />
                    </button>
                </div>
            </ModalComponent>
        </EditableArea>

        <EditableArea :content="cancellationSuccessModalArea" :custom-view="EmptyArea">
            <ModalComponent
                :controller="cancellationSuccessModal"
                :content="cancellationSuccessModalArea"
                :text-centered="true"
            >
                <template #icon>
                    <SvgIcon class="h-20 w-20 mx-auto text-dark-blue" name="Checkmark-Circle-Thin" />
                </template>
                <NuxtLink class="btn btn-blue mx-auto" :to="marketSetupAdmin?.myBritaOverviewPage ?? ''">
                    <CommonLabel path="BWF/shop/account/subscriptions/modal/backToMyBRITA" />
                </NuxtLink>
            </ModalComponent>
        </EditableArea>

        <EditableArea :content="subscriptionCancellationSuccessModalArea" :custom-view="EmptyArea">
            <ModalComponent
                :controller="subscriptionCancellationSuccessModal"
                :content="subscriptionCancellationSuccessModalArea"
                :text-centered="true"
            >
                <template #icon>
                    <SvgIcon class="h-20 w-20 mx-auto text-dark-blue" name="Checkmark-Circle-Thin" />
                </template>
                <NuxtLink class="btn btn-blue mx-auto" :to="marketSetupAdmin?.myBritaOverviewPage ?? ''">
                    <CommonLabel path="BWF/shop/account/subscriptions/modal/backToMyBRITA" />
                </NuxtLink>
            </ModalComponent>
        </EditableArea>

        <EditableArea :content="changePaymentModalArea" :custom-view="EmptyArea">
            <ModalComponent :controller="changePaymentModal" :content="changePaymentModalArea" :text-centered="true">
                <div class="flex flex-col gap-4">
                    <legend class="col-span-full">
                        <h3 class="font-medium">
                            <CommonLabel path="BWF/shop/checkout/complete/paymentMethod"></CommonLabel>
                        </h3>
                    </legend>

                    <PaymentMethodSelection
                        ref="paymentMethodSelection"
                        :payment-methods="paymentMethods"
                        :selected-method="selectedPaymentMethod"
                        @change="onMethodChange"
                    ></PaymentMethodSelection>
                </div>

                <template #primaryAction>
                    <button class="btn btn-ghost w-full" @click="changePaymentModal.close()">
                        <CommonLabel path="BWF/shop/account/subscriptions/modal/abortPaymentChange" />
                    </button>
                </template>
                <template #secondaryAction>
                    <button class="btn btn-blue w-full" :disabled="!selectedPaymentMethod" @click="onChangePayment()">
                        <CommonLabel path="BWF/shop/account/subscriptions/modal/confirmPaymentChange" />
                    </button>
                </template>
            </ModalComponent>
        </EditableArea>
    </AccountGrid>
</template>

<script setup lang="ts">
import AccountGrid from '~/templates/components/account/AccountGrid.vue';
import { watch } from 'vue';
import LoadingElement from '~/templates/elements/LoadingElement.vue';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import EmptyArea from '~/templates/areas/EmptyArea.vue';
const { marketSetupAdmin } = useMarketSetup();
//@ts-ignore
import { EditableArea } from '@magnolia/vue-editor';
import { useShopwareContext } from '@shopware-pwa/composables-next';
import SubscriptionContract from '~/templates/components/shop/subscription/SubscriptionContract.vue';
const { apiInstance } = useShopwareContext();
import { isEditMode } from '~/utils/content/magnolia';
import ModalComponent from '~/templates/components/ModalComponent.vue';
import SvgIcon from '~/templates/elements/SvgIcon.vue';
import PaymentMethodSelection from '~/templates/components/shop/checkout/PaymentMethodSelection.vue';
const { pushError, pushSuccess } = useNotifications();

const { fullPath } = useRoute();
const {
    public: { baseUrl },
} = useRuntimeConfig();
const { isLoggedIn } = useUser();

defineOptions({
    inheritAttrs: false,
});
defineProps([
    'informationArea',
    'paymentExpirationWarningArea',
    'empty',
    'cancellationModalArea',
    'cancellationSuccessModalArea',
    'subscriptionCancellationModalArea',
    'subscriptionCancellationSuccessModalArea',
    'changePaymentModalArea',
]);

const { getLabels } = useCommonLabels();
const commonLabels = getLabels('BWF/shop/account/subscriptions/contract', ['subscriptionChangeSuccess']);

const userContracts = ref({});
const totalDeposit = ref(0);
const paymentMethods = ref({});

const isLoading = ref(true);

const loadContracts = async () => {
    isLoading.value = true;
    const {
        data: { contracts, deposit },
    } = await apiInstance.invoke.get('/store-api/contract');

    userContracts.value = contracts;
    totalDeposit.value = deposit;

    isLoading.value = false;
};

const paymentExpirationWarning = computed(() => {
    return userContracts?.value?.elements.find(
        contract => contract?.extensions?.creditCardExpiryState || contract?.status === 'payment_update_requested'
    );
});

const loadPaymentMethods = async () => {
    isLoading.value = true;
    const {
        data: { elements },
    } = await apiInstance.invoke.get('/store-api/contract/payment-method');

    paymentMethods.value = elements;
    isLoading.value = false;
};

watch(
    isLoggedIn,
    async () => {
        if (isLoggedIn.value) {
            await loadContracts();
            await loadPaymentMethods();
        }
    },
    { immediate: isLoggedIn.value }
);

onMounted(() => {
    if (isEditMode()) {
        isLoading.value = false;
    }
});

const cancellationModal = useModal();
const cancellationSuccessModal = useModal();
const changePaymentModal = useModal();
const subscriptionCancellationSuccessModal = useModal();
const subscriptionCancellationModal = useModal();

const currentContractId = ref('');

const contractCancel = (id: string, contractType: string) => {
    currentContractId.value = id;
    if (contractType === 'exchangebox') {
        cancellationModal.open();
    }
    if (contractType === 'subscription') {
        subscriptionCancellationModal.open();
    }
};
const onCancelSubscription = async () => {
    try {
        await apiInstance.invoke.post(`/store-api/contract/${currentContractId.value}/cancel`);
        cancellationModal.close();
        cancellationSuccessModal.open();
    } catch (e) {
        //@ts-ignore
        pushError(e?.messages?.[0]?.detail);
    }
};

const selectedPaymentMethod = ref('');
const paymentMethodSelection = ref(null);

const changePayment = (id: string) => {
    currentContractId.value = id;
    changePaymentModal.open();
};
const onMethodChange = method => {
    selectedPaymentMethod.value = method;
};
const onChangePayment = async () => {
    const isValid = await paymentMethodSelection.value?.validate();
    if (isValid) {
        const payload = {
            contractId: currentContractId.value,
            paymentMethodId: selectedPaymentMethod.value,
            finishUrl: `${baseUrl}${fullPath}`,
            // TODO call endpoint, handle errors !? Maybe in scope of: D2CMVP-3604 ?
            errorUrl: `${baseUrl}${fullPath}`,
        };

        try {
            const { data } = await apiInstance.invoke.post(`/store-api/contract/payment/update`, payload);
            pushSuccess(commonLabels.subscriptionChangeSuccess ?? '*');
            if (data.redirectUrl) {
                // needs payment
                location.href = data.redirectUrl;
                return;
            }

            changePaymentModal.close();
        } catch (e) {
            pushError(commonLabels.subscriptionChangeError ?? '*');
        }
    } else {
        pushError(commonLabels.subscriptionChangeError ?? '*');
    }
};
</script>
