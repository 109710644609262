<template>
    <div>
        <div
            class="relative py-12 xl:py-16 rounded-xl"
            :class="
                contentToTailwindClasses({
                    backgroundColor,
                })
            "
        >
            <DotPattern v-if="dotPattern" :variant="dotPattern"></DotPattern>
            <div class="relative flex flex-col items-center md:items-start gap-4 md:flex-row w-10/12 mx-auto">
                <div v-if="containsImage(image)" class="md:w-5/12 xl:w-3/12 shrink-0 grow-0">
                    <PictureElement
                        :img="image"
                        img-class="w-44 h-auto shrink-0 rounded-xl safari-overflow-hidden-fix overflow-hidden"
                        :breakpoints="BREAKPOINTS"
                    ></PictureElement>
                </div>

                <div
                    class="flex flex-1 flex-col gap-4 xl:gap-6 w-full"
                    :class="[{ 'text-white': backgroundColor === 'magenta' }]"
                >
                    <SvgIcon
                        v-if="showQuote"
                        class="text-dark-blue w-10 h-10 xl:w-16 xl:h-16 xl:mb-2"
                        :class="[{ 'text-white': backgroundColor === 'magenta' }]"
                        name="icon-quote"
                    ></SvgIcon>

                    <div>
                        <HeadlineComponent
                            :headline="headline"
                            class="font-medium"
                            :class="
                                contentToTailwindClasses({
                                    fontSize: fontSize,
                                })
                            "
                        ></HeadlineComponent>
                    </div>
                    <div>{{ subline }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import SvgIcon from '../../elements/SvgIcon';
import HeadlineComponent from './HeadlineComponent';
import PictureElement from '../../elements/PictureElement';
import { contentToTailwindClasses } from '~/utils/helper/tailwind';
import { containsImage } from '~/utils/helper/magnolia';

import { DIMENSIONS } from '~/utils/helper/imaging';
import DotPattern from '~/templates/elements/DotPattern.vue';
const BREAKPOINTS = {
    start: DIMENSIONS['sm'].width['1/2'].ratio['original'],
};

defineProps(['headline', 'fontSize', 'subline', 'showQuote', 'image', 'backgroundColor', 'dotPattern']);
</script>
