<template>
    <div v-if="!product?.available" class="badge">
        <CommonLabel path="BWF/shop/productCard/soldOutLabel"></CommonLabel>
    </div>
    <div v-else-if="product?.isNew" class="badge is-blue">
        <CommonLabel path="BWF/shop/productCard/newLabel"></CommonLabel>
    </div>
    <div v-else-if="isEvaluatedListPrice || product?.markAsTopseller" class="flex gap-1">
        <div v-if="isEvaluatedListPrice" class="badge is-pink">
            <CommonLabel path="BWF/shop/productCard/sale"></CommonLabel>
        </div>
        <div v-if="product?.markAsTopseller" class="badge">
            <CommonLabel path="BWF/shop/productCard/topsellerLabel"></CommonLabel>
        </div>
    </div>
</template>
<script setup lang="ts">
import type { Product } from '@shopware-pwa/types';
import type { ProductBulkPrice } from '~/composables/shop/useProductBulkPrices';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import { useProductPrice } from '@shopware-pwa/composables-next';

const props = defineProps<{
    product?: Product;
    activeBulkChild?: ProductBulkPrice;
}>();
const { product } = useProduct(props.product);
const { isListPrice } = useProductPrice(product);
const { hasBulkPrice, activeBulkChild } = useProductBulkPrices(product);

const isEvaluatedListPrice = computed(() => {
    return hasBulkPrice.value ? !!activeBulkChild.value?.listPrice : isListPrice.value;
});
</script>
