<template>
    <div class="reveal-component" :class="{ 'is-open': isOpen, content: isActive }">
        <div ref="contentMeasure">
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    isActive: {
        type: Boolean,
        default: true,
    },
    open: {
        type: Boolean,
        default: false,
    },
});

const isOpen = ref(props.open);
const contentMeasure = ref();
const contentHeight = ref(`${contentMeasure.value?.offsetHeight}px`);

useResizeObserver(contentMeasure, entries => {
    const entry = entries[0];
    if (isOpen.value) {
        const { height } = entry.contentRect;
        contentHeight.value = `${height}px`;
    }
});

const toggleOpen = () => {
    contentHeight.value = `${contentMeasure.value?.offsetHeight}px`;
    isOpen.value = props.open;
};

watch(
    () => props.open,
    () => toggleOpen()
);
</script>

<style scoped>
.content {
    @apply overflow-y-hidden h-0 transition-all duration-500;
}

.is-open {
    height: v-bind(contentHeight);
}
</style>
