<template>
    <div v-if="exchangeBoxVariants?.length > 1" class="exchange-box-variant-configurator">
        <div v-if="isLoading" class="absolute inset-0 flex items-center justify-end z-10 bg-white/75"></div>

        <CommonLabel
            tag="div"
            class="xl:text-2xl font-medium"
            path="BWF/shop/productDetailPage/exchangeBox/configuratorHeadline"
        ></CommonLabel>
        <CommonLabel
            tag="div"
            class="xl:text-2xl font-medium mt-6"
            path="BWF/shop/productDetailPage/exchangeBox/cylinderHeadline"
        ></CommonLabel>
        <CommonLabel
            tag="div"
            class="xl:text-2xl mt-2"
            path="BWF/shop/productDetailPage/exchangeBox/cylinderQuestion"
        ></CommonLabel>
        <CommonLabel
            tag="div"
            class="text-medium-grey text-sm mt-1"
            path="BWF/shop/productDetailPage/exchangeBox/cylinderQuestionHint"
        ></CommonLabel>

        <fieldset class="block flex-1 mt-2 lg:mt-6">
            <div class="switch-container">
                <SelectField
                    v-model="selectedOption"
                    label="Anzahl Zylinder zuhause"
                    class="w-full"
                    :options="selectableOptions"
                />
            </div>
        </fieldset>
    </div>
</template>

<script setup>
import SelectField from '~/templates/elements/form/SelectField.vue';
import { useExchangeBox } from '~/composables/shop/useExchangeBoxProduct';
import CommonLabel from '~/templates/components/CommonLabel.vue';

const emit = defineEmits(['change', 'beforeChange']);
const isLoading = ref(false);

const { product } = useProduct();

const { exchangeBoxVariants, maxAmount } = await useExchangeBox();

const selectedOption = ref(product.value.id);

const selectableOptions = computed(() =>
    exchangeBoxVariants.value?.map?.(option => ({
        value: option.id,
        label: 1 + (maxAmount.value - parseInt(option.customFields['contract_amount_cylinders'])),
    }))
);

watch(selectedOption, () => {
    emit('beforeChange');
    const selectedVariant = exchangeBoxVariants.value?.find(variant => variant.id === selectedOption.value);
    emit('change', selectedVariant);
});
</script>
