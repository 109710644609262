<template>
    <span>{{ formatted }}</span>
</template>

<script setup>
import { useNow, useDateFormat } from '@vueuse/core';
import useMagnoliaLanguage from '~/composables/useMagnoliaLanguage';
const props = defineProps(['date', 'pattern']);
const { currentLanguage } = useMagnoliaLanguage();

const formatterLocale = currentLanguage.value?.replace('_', '-');
const formatted = useDateFormat(props.date ?? props.date ?? useNow(), props.pattern ?? 'D.M.YYYY', {
    locales: formatterLocale,
});
</script>
