<template>
    <button
        data-testid="product-variant"
        :aria-label="`color-${option.name ?? 'button'}`"
        class="switch-btn flex-0 rounded-full flex items-center justify-center overflow-hidden"
        :class="[isOptionSelected ? 'border-dark-blue' : 'border-beige/50']"
    >
        <div
            class="relative switch-btn-inner rounded-full bg-light-grey border-beige/50"
            :style="{ backgroundColor: option?.colorHexCode ?? '#000' }"
        >
            <span :id="`${option.id}-choice-label`" data-testid="product-variant-text">
                <!--                {{ option.name }}-->
            </span>
            <StrikeThroughLine
                v-if="isOptionNotAvailable || isOptionNotCombinable"
                class="absolute inset-0 h-full w-full text-beige"
            ></StrikeThroughLine>
        </div>
    </button>
</template>

<script setup lang="ts">
import StrikeThroughLine from '~/templates/partials/header/StrikeThroughLine.vue';

const props = defineProps(['option']);

const { product } = useProduct();
const isOptionNotCombinable = computed((): boolean => {
    // @ts-ignore
    return props.option?.extensions?.combinable?.combinable === false;
});

const isOptionNotAvailable = computed((): boolean => {
    return !product.value.available && isOptionSelected.value;
});

const isOptionSelected = computed(() => product.value?.optionIds?.includes(props.option.id));
</script>

<style>
.switch-btn {
    @apply h-5 xl:h-7 w-5 xl:w-7 border;
    .switch-btn-inner {
        @apply h-4 xl:h-6 w-4 xl:w-6 border;
    }
}
</style>
