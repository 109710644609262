<template>
    <div class="stage-success">
        <div
            class="stage-content relative w-full aspect-square sm:aspect-3/2 lg:aspect-2/1 xl:aspect-[3/1] container:aspect-[4/1] overflow-hidden"
        >
            <div class="absolute inset-0 bg-light-grey">
                <PictureElement
                    :img="image"
                    :breakpoints="BREAKPOINTS"
                    loading="eager"
                    img-class=" absolute inset-0  block object-cover w-full h-full"
                ></PictureElement>
                <div
                    class="absolute inset-0 bg-black to-transparent"
                    :class="contentToTailwindClasses({ opacity: opacity ?? 25 })"
                ></div>
                <div class="text-container container relative h-full w-full py-8">
                    <div class="w-full mx-auto md:w-8/12 h-full flex flex-col justify-center">
                        <div
                            class="checkmark-success mb-8 border-white self-center items-center justify-center rounded-full flex border-[3px] xl:border-4"
                            :class="{ 'play-animation': animate }"
                        >
                            <SvgIcon class="h-8 w-8 m-5 xl:h-12 xl:w-12 text-white" name="Checkmark-1"></SvgIcon>
                        </div>

                        <HeadlineComponent
                            v-if="headline"
                            class="font-medium text-2xl xl:text-4xl text-center mb-4"
                            :class="[containsImage(image) ? 'text-white' : 'text-dark-blue']"
                            :headline="headline"
                            tag="h1"
                        ></HeadlineComponent>
                        <div class="transition-opacity" :class="[animate ? 'opacity-100' : 'opacity-0']">
                            <div class="text-center" :class="[containsImage(image) ? 'text-white' : 'text-black']">
                                {{ isSubscriptionSuccess ? subscriptionSubline : subline }}

                                <NuxtLink
                                    v-if="isSubscriptionSuccess"
                                    :class="[containsImage(image) ? 'text-white' : 'text-black']"
                                    class="text-link"
                                    :to="marketSetupAdmin?.myBritaSubscriptionPage ?? ''"
                                >
                                    <CommonLabel path="/BWF/shop/checkout/success/myBritaLinkLabel"></CommonLabel>
                                </NuxtLink>
                                <LinkComponent
                                    v-else-if="link?.label ?? false"
                                    :class="[containsImage(image) ? 'text-white' : 'text-black']"
                                    class="text-link"
                                    :link="link"
                                >
                                    <span>{{ link?.label }}</span>
                                </LinkComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { DIMENSIONS } from '~/utils/helper/imaging';
import { containsImage } from '~/utils/helper/magnolia';
import { contentToTailwindClasses } from '~/utils/helper/tailwind';
import HeadlineComponent from '~/templates/components/cms/HeadlineComponent.vue';
import PictureElement from '~/templates/elements/PictureElement.vue';
import LinkComponent from '~/templates/components/cms/LinkComponent.vue';
import SvgIcon from '~/templates/elements/SvgIcon.vue';
import CommonLabel from '~/templates/components/CommonLabel.vue';
const { marketSetupAdmin } = useMarketSetup();
const props = defineProps(['content', 'animate', 'isSubscriptionSuccess']);

const { subline, subscriptionSubline, headline, image, link, opacity } = props.content ?? {};

const BREAKPOINTS = {
    start: DIMENSIONS['start'].ratio['3/4'],
    sm: DIMENSIONS['sm'].ratio['3/4'],
    md: DIMENSIONS['md'].ratio['3/4'],
    xl: DIMENSIONS['xl'].ratio['2/1'],
    container: DIMENSIONS['container'].ratio['2/1'],
    page: DIMENSIONS['page'].ratio['2/1'],
};
</script>

<style scoped>
.text-container {
    @apply transition-all;
    @screen md {
        padding-top: var(--fixed-header-height, 0px);
    }
}
.checkmark-success.play-animation {
    animation: 2s ease 0ms 1 normal none running successBounce;
}

@keyframes successBounce {
    0% {
        transform: translateY(0);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    16.6% {
        transform: translateY(-25%);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    33.2% {
        transform: translateY(0);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    49.8% {
        transform: translateY(-15%);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    66.4% {
        transform: translateY(0);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    83% {
        transform: translateY(-5%);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    100% {
        transform: translateY(0);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}
</style>
