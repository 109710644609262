import { ref } from 'vue';
import { useShopwareContext } from '@shopware-pwa/composables-next';
import useAzureUser from '~/composables/useAzureUser';
import { createSharedComposable } from '@vueuse/core';

const RESTRICTED_TEMPLATES = [
    'd2c-lm:pages/account/address',
    'd2c-lm:pages/account/order',
    'd2c-lm:pages/account/order-return',
    'd2c-lm:pages/account/order-details',
    'd2c-lm:pages/account/order-warranty',
    'd2c-lm:pages/account/overview',
    'd2c-lm:pages/account/profile',
    'd2c-lm:pages/account/subscriptions',
];

function singleUseBritaUser(sessionContext = null) {
    const { azureUser, isAzureAuthenticated } = useAzureUser();
    // if azure authenticated consider user to be in progress
    const inAuthenticationProgress = ref(isAzureAuthenticated.value === true);
    const { apiInstance } = useShopwareContext();
    const { $msal } = useNuxtApp();
    const { refreshSessionContext } = sessionContext ?? useSessionContext();
    const { refreshCart } = useCart();
    const { isLoggedIn, user, logout } = useUser();

    const handleB2CSession = async () => {
        const { idToken, username } = azureUser.value;

        try {
            if (idToken && (!isLoggedIn.value || user.value?.email?.toLowerCase() !== username?.toLowerCase())) {
                inAuthenticationProgress.value = true;
                /**
                 * user has an ADB2C token
                 * user is not logged in @shopware
                 * user is logged in, in both systems but azure email differs from shopware email -> renew
                 */
                try {
                    await apiInstance.invoke.post('/store-api/account/b2clogin', {
                        id_token: idToken,
                    });
                    await refreshSessionContext();
                } catch (e) {
                    console.log('HandleB2CSession:b2cLogin:Error', e);
                    // logout of msal as well
                    await $msal.signOut();
                }
            } else if (!idToken && isLoggedIn.value) {
                inAuthenticationProgress.value = true;
                /**
                 * user has no token but is still logged in at shopware -> sw logout
                 */
                try {
                    await logout();
                } catch (e) {
                    console.log('HandleB2CSession:SWLogout:Error', e);
                    const swToken = useCookie('sw-context-token');
                    swToken.value = '';
                }
            }
        } catch (e) {
            console.log('HandleB2CSession:Error', e);
        }
        inAuthenticationProgress.value = false;
    };

    const myBritaLogout = async () => {
        inAuthenticationProgress.value = true;
        try {
            await logout();
        } catch (e) {
            console.log(e);
        }
        await $msal.signOut();
        inAuthenticationProgress.value = false;
    };

    const isAuthenticated = computed(() => {
        return isAzureAuthenticated.value ?? false;
    });
    // this is a proper login
    const isMyBritaLoggedIn = computed(() => isAuthenticated.value && isLoggedIn.value);

    const verifyLogIn = () => {
        return new Promise((resolve, reject) => {
            watch(
                isMyBritaLoggedIn,
                () => {
                    if (isMyBritaLoggedIn.value) {
                        resolve(true);
                    } else {
                        reject();
                    }
                },
                { immediate: isMyBritaLoggedIn.value || inAuthenticationProgress.value === false }
            );
        });
    };

    const validateContent = async content => {
        const isRestricted = RESTRICTED_TEMPLATES.includes(content?.value?.['mgnl:template']);
        if (isRestricted) {
            // need to
            try {
                await verifyLogIn();
            } catch (e) {
                if (process.client) {
                    await $msal.signIn();
                }
            }
        }
    };

    watch(
        () => azureUser.value,
        async () => {
            await handleB2CSession();
        },
        { immediate: process.client }
    );

    // expose managed state as return value
    return {
        isAuthenticated,
        inAuthenticationProgress,
        isMyBritaLoggedIn,
        verifyLogIn,
        validateContent,
        myBritaLogout,
    };
}

export default createSharedComposable(singleUseBritaUser);
