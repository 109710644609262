<template>
    <div>
        <EditableArea v-if="main" :content="main" />

        <LoadingElement
            v-if="isLoading"
            class="bg-transparent container w-full aspect-square md:aspect-video"
        ></LoadingElement>
        <form v-else class="container grid grid-cols-12 my-12 relative" @submit.prevent="onSubmit()">
            <div class="mt-8 col-span-full flex flex-col gap-2 xl:gap-4 xl:col-span-8 xl:col-start-3">
                <BackButton class="mb-2 xl:mb-6"></BackButton>

                <div v-if="paymentCancelled" class="notification flex gap-2 items-start" data-type="warning">
                    <SvgIcon class="notification-icon" name="Error-Circle"></SvgIcon>

                    <div>
                        <div class="font-bold">
                            <CommonLabel path="BWF/shop/account/orderDetails/orderPayment/errorHeadline"> </CommonLabel>
                        </div>
                        <CommonLabel path="BWF/shop/account/orderDetails/orderPayment/errorMessage"> </CommonLabel>
                    </div>
                </div>
                <div v-else class="notification flex gap-2 items-start">
                    <div>
                        <div class="font-bold">
                            <CommonLabel
                                path="BWF/shop/account/orderDetails/orderPayment/notificationHeadline"
                            ></CommonLabel>
                        </div>
                        <CommonLabel
                            path="BWF/shop/account/orderDetails/orderPayment/notificationMessage"
                        ></CommonLabel>
                    </div>
                </div>

                <div class="mb-2 xl:mb-0 text-lg xl:text-4xl">
                    <h2 v-if="paymentCancelled" class="text-dark-blue font-medium">
                        <CommonLabel path="BWF/shop/account/orderDetails/orderPayment/headline"></CommonLabel>
                    </h2>
                    <h3 class="flex flex-wrap gap-1 gap-x-4 xl:gap-4 items-center">
                        <span>
                            <CommonLabel path="BWF/shop/account/orderDetails/orderNumber"></CommonLabel>
                            <span>&nbsp;{{ order?.orderNumber }}</span>
                        </span>
                        <AccountOrderStateLabel :state="order?.stateMachineState"></AccountOrderStateLabel>
                    </h3>
                </div>
                <hr class="hidden xl:block xl:mt-2 xl:mb-4" />

                <OrderDetailsHeader></OrderDetailsHeader>
                <hr class="hidden xl:block" />
                <OrderDetailsAddresses></OrderDetailsAddresses>
                <template v-if="paymentCancelled">
                    <hr class="hidden xl:block" />
                    <div class="my-4 grid gap-4">
                        <legend class="col-span-full">
                            <h3 class="font-medium">
                                <CommonLabel path="BWF/shop/checkout/complete/paymentMethod"></CommonLabel>
                            </h3>
                        </legend>

                        <PaymentMethodSelection
                            ref="paymentMethodSelection"
                            v-model:selected-payment-method="selectedPaymentMethod"
                            :payment-methods="availablePaymentMethods"
                        ></PaymentMethodSelection>
                    </div>
                </template>
                <hr class="hidden xl:block" />
                <OrderLineItems class="mt-4" :order="order" />
                <hr class="mt-4 mb-6 w-full" />
                <OrderLineItemsPrice class="mt-4" :order="order" />

                <template v-if="marketSetupAdmin?.legalCheckbox">
                    <p class="hidden xl:block">
                        <CommonLabel path="BWF/shop/checkout/complete/rightOfWithdrawal"></CommonLabel>
                    </p>

                    <CheckboxField
                        v-if="marketSetupAdmin?.legalCheckbox"
                        v-model="legalState.legal"
                        name="legal"
                        :error-message="vuelidateLegal?.legal?.$errors?.[0]?.$message"
                        @update:model-value="vuelidateLegal.legal.$touch()"
                    >
                        <CommonLabel path="BWF/shop/checkout/complete/privacyPolicyNotice"></CommonLabel>
                    </CheckboxField>
                </template>
                <template v-else>
                    <CommonLabel path="BWF/shop/checkout/complete/rightOfWithdrawalNoCheckbox"></CommonLabel>
                    <CommonLabel path="BWF/shop/checkout/complete/privacyPolicyNoticeNoCheckbox"></CommonLabel>
                </template>

                <hr class="col-span-full my-10 hidden xl:block" />
                <div class="col-span-full grid gap-8 xl:grid-cols-3">
                    <button
                        class="btn btn-blue btn-size-normal mt-4 xl:mt-0"
                        type="submit"
                        :disabled="!paymentCancelled"
                    >
                        <span>
                            <CommonLabel
                                path="BWF/shop/account/orderDetails/orderPayment/updatePaymentMethod"
                            ></CommonLabel>
                        </span>
                    </button>
                    <div class="xl:col-span-2 xl:order-first">
                        <button class="text-icon-link mx-auto xl:mx-0" @click="$router.go(-1)">
                            <SvgIcon name="Arrow-Up" class="h-3 w-3 -rotate-90"></SvgIcon>
                            <span><CommonLabel path="BWF/shop/shared/backToShop"></CommonLabel> </span>
                        </button>
                    </div>
                </div>
            </div>
        </form>
        <EditableArea v-if="bottom" :content="bottom" />
    </div>
</template>

<script setup>
import CommonLabel from '~/templates/components/CommonLabel.vue';
import { useShopwareContext } from '@shopware-pwa/composables-next';
import { EditableArea } from '@magnolia/vue-editor';
import SvgIcon from '~/templates/elements/SvgIcon.vue';
import BackButton from '~/templates/components/BackButton.vue';
import LoadingElement from '~/templates/elements/LoadingElement.vue';
import {
    hasFailedPayment,
    hasInProgressPayment,
    ORDER_DETAILS_ASSOCIATIONS,
    orderQueryString,
} from '~/utils/helper/shop/order';
import useMagnoliaContent from '~/composables/useMagnoliaContent';
import { isEditMode } from '~/utils/content/magnolia';
import OrderDetailsHeader from '~/templates/components/shop/account/OrderDetailsHeader.vue';
import OrderDetailsAddresses from '~/templates/components/shop/account/OrderDetailsAddresses.vue';
import OrderLineItems from '~/templates/components/shop/account/OrderLineItems.vue';
import CheckboxField from '~/templates/elements/form/CheckboxField.vue';
import { useVuelidate } from '@vuelidate/core';
import OrderLineItemsPrice from '~/templates/components/shop/account/OrderLineItemsPrice.vue';
import useCommonLabels from '~/composables/useCommonLabels';
import PaymentMethodSelection from '~/templates/components/shop/checkout/PaymentMethodSelection.vue';
import { useGuestOrderDetails } from '~/composables/shop/useGuestOrderDetails';
import { provide } from 'vue';
import useShopwareNotifications from '~/composables/useShopwareNotifications';

const { pushError } = useNotifications();
const { pushShopwareError } = useShopwareNotifications();

const { apiInstance } = useShopwareContext();
const { verifyLogIn } = useMyBritaUser();
const { marketSetupAdmin } = useMarketSetup();
const { getLabelByPath } = useCommonLabels();

const { pathVariable } = useMagnoliaContent();
const { query } = useRoute();

const orderId = pathVariable?.value?.replaceAll('/', '');
const isGuestQuery = !!orderId && !!query.token;

const {
    order: regularOrder,
    loadOrderDetails,
    changePaymentMethod,
} = useOrderDetails(orderId, ORDER_DETAILS_ASSOCIATIONS);

const { loadGuestOrder, guestOrder, changeGuestOrderPaymentMethod } = useGuestOrderDetails();
defineProps(['main', 'bottom', 'serviceModalArea', 'cancellationModalArea']);
defineOptions({
    inheritAttrs: false,
});

const isLoading = ref(true);

const { paymentMethods, getPaymentMethods } = useCheckout();
const { selectedPaymentMethod: paymentMethod, setPaymentMethod } = useSessionContext();

const availablePaymentMethods = computed(() => {
    return paymentMethods.value.filter(method => method.id === selectedPaymentMethod.value);
});

const selectedPaymentMethod = computed({
    get() {
        return paymentMethod?.value?.id || '';
    },
    async set(paymentMethodId) {
        await setPaymentMethod({ id: paymentMethodId });
    },
});
const {
    public: { baseUrl },
} = useRuntimeConfig();

const { consentValidators } = await useValidation();

const paymentCancelled = computed(() => hasFailedPayment(order.value));
// this is a basic page for now
const legalRules = computed(() => ({
    legal: { ...consentValidators },
}));

const order = computed(() => {
    // prefer guest order as provide value over regular
    return guestOrder?.value ?? regularOrder?.value;
});

provide('swOrderDetails', order);

// set legal straight to true if it's not required
const legalState = reactive({
    legal: !marketSetupAdmin.value.legalCheckbox,
});
const vuelidateLegal = useVuelidate(legalRules, legalState);

const paymentMethodSelection = ref(null);
const onSubmit = async () => {
    if (!selectedPaymentMethod.value) {
        const message = getLabelByPath('BWF/shop/account/orderDetails/orderPayment/noPaymentMethodError');
        // no payment selected
        pushError(message);
        return;
    }

    vuelidateLegal.value.$touch();
    const paymentValid = (await paymentMethodSelection.value?.validate()) ?? true;
    const formValid = await vuelidateLegal.value.$validate();
    const valid = formValid && paymentValid;

    if (valid) {
        isLoading.value = true;

        try {
            if (isGuestQuery) {
                await changeGuestOrderPaymentMethod(selectedPaymentMethod.value, order.value?.orderCustomer?.email);
            } else {
                await changePaymentMethod(selectedPaymentMethod.value, order.value?.orderCustomer?.email);
            }
            const cmsSuccessUrl = `${marketSetupAdmin.value?.checkoutSuccessPage}/${order.value.id}`;
            const cmsPaymentUrl = `${marketSetupAdmin.value?.orderPaymentPage}/${order.value.id}`;

            const queryString = isGuestQuery ? `?${orderQueryString(order.value)}` : '';
            const paymentData = {
                orderId: order.value.id,
                finishUrl: `${baseUrl}${cmsSuccessUrl}${queryString}`,
                errorUrl: `${baseUrl}${cmsPaymentUrl}${queryString}`,
            };

            const { data } = await apiInstance.invoke.post('/store-api/handle-payment', paymentData);
            if (data.redirectUrl) {
                // needs payment
                await navigateTo(data.redirectUrl, { external: true });
                return;
            }
            navigateTo(cmsSuccessUrl);
        } catch (e) {
            await pushShopwareError(e);
        }
        isLoading.value = false;
    }
};

const loadOrder = async () => {
    if (isGuestQuery) {
        // load and provide order as guest
        await loadGuestOrder(orderId, query.token);
    } else {
        // verify login status to await client side authentification
        await verifyLogIn();
        // load order as regular customer
        await loadOrderDetails();
    }
};

const { trackPaymentError } = useCheckoutTracking();

onMounted(async () => {
    try {
        await loadOrder();

        if (hasInProgressPayment(order.value)) {
            try {
                // cancel open payments
                await apiInstance.invoke.post(`/store-api/order/${order.value.id}/cancel_open_payments`, {
                    customer_email: order.value.orderCustomer.email,
                });
            } catch (e) {
                console.error(e);
            }
            // reload details
            await loadOrder();
        }
        if (order?.value?.transactions?.[0]?.paymentMethod) {
            // set selected payment method to the last one used in order
            selectedPaymentMethod.value = order?.value?.transactions?.[0]?.paymentMethodId;
        }
        await getPaymentMethods({ forceReload: true, orderId: order.value?.id });
        trackPaymentError(paymentMethod);
    } catch (e) {
        console.error(e);
        if (!isEditMode()) {
            showError({ statusCode: 404, statusMessage: 'not found' });
        }
    }
    isLoading.value = false;
});

watch([isLoading], () => {
    window.scrollTo(0, 0);
});
</script>
