export const COMMON_LABEL_DEFAULTS = {
    /* BWF SHARED */
    'BWF/shared/back': 'Zurück*',
    'BWF/shared/continueShopping': 'Weiter einkaufen*',
    'BWF/shared/save': 'Änderungen speichern*',
    'BWF/shared/more': 'Mehr*',
    'BWF/shared/skipToMainContent': 'Skip to main content.*',
    'BWF/shared/emailAddressLabel': 'E-Mail Adresse:*',
    'BWF/shared/emailInputPlaceholder': 'example@domain.com*',
    'BWF/shared/errorText': 'Es ist ein Fehler aufgetreten*',
    'BWF/shared/notifications/setDefaultBillingAddressError': 'Fehler beim festlegen der Standard-Rechnungsadresse *',
    'BWF/shared/notifications/setDefaultBillingAddressSuccess': 'Standard-Rechnungsadresse erfolgreich festgelegt*',
    'BWF/shared/notifications/setDefaultShippingAddressError': 'Fehler beim festlegen der Standardversandadresse *',
    'BWF/shared/notifications/setDefaultShippingAddressSuccess': 'Standardversandadresse erfolgreich festgelegt *',

    'BWF/shared/middleware/errors/IncorrectJson': 'IncorrectJson *',
    'BWF/shared/middleware/errors/NoFileNameValue': 'NoFile *',
    'BWF/shared/middleware/errors/NoFileName': 'NoFileName *',
    'BWF/shared/middleware/errors/NoFileType': 'NoFileType *',
    'BWF/shared/middleware/errors/NoAllowedFileType': 'NoAllowedFileType *',
    'BWF/shared/middleware/errors/zipCodeError': 'Die PLZ hat das falsche Format*',
    'BWF/shared/vatFootnote': '* Alle Preise und Beträge inkl. MwSt. *',

    /* FORM SHARED */
    'BWF/form/shared/requiredFieldLabel': '* Pflichtfelder AUS MAP*',
    'BWF/form/shared/privacyLinkLabel': 'Hinweise zum Datenschutz finden sie {{ dataPrivacyPage | hier }}*',
    'BWF/form/shared/remainingCharsLabel': '{{remainingChars}} Zeichen übrig*',
    'BWF/form/shared/salutations/not_specified': 'Keine Angabe*',
    'BWF/form/shared/salutations/mr': 'Herr*',
    'BWF/form/shared/salutations/mrs': 'Frau*',
    'BWF/form/shared/salutations/diverse': 'Divers*',
    /**
     * Form Validator Messages
     * https://vuelidate-next.netlify.app/validators.html
     */
    'BWF/form/shared/validatorMessages/required': 'Wert ist erforderlich*',
    'BWF/form/shared/validatorMessages/email': 'Keine gültige E-Mail*',
    'BWF/form/shared/validatorMessages/minLengthValue': 'zu kurz*',
    'BWF/form/shared/validatorMessages/maxLengthValue': 'zu lang*',
    'BWF/form/shared/validatorMessages/sameAs': 'Wert ist erforderlich*',
    'BWF/form/shared/validatorMessages/phone': 'Wert ist keine gültige Telefonnummer*',
    'BWF/form/shared/validatorMessages/regex': 'Wert enthält ungültige Zeichen*',
    'BWF/form/shared/validatorMessages/message': 'Die Nachricht enthält ungültige Zeichen*',
    'BWF/form/shared/validatorMessages/oneItemRequired': 'Mindestens ein Produkt muss ausgewählt sein*',
    'BWF/form/shared/validatorMessages/birthdate': 'Das ist kein gültiges Geburtsdatum*',

    /* FORM CONTACT */
    'BWF/form/contact/label/sendButtonLabel': 'Absenden*',
    'BWF/form/contact/label/subject': 'subject*',
    'BWF/form/contact/label/message': 'message*',
    'BWF/form/contact/label/company': 'company*',
    'BWF/form/contact/label/salutation': 'salutation*',
    'BWF/form/contact/label/title': 'title*',
    'BWF/form/contact/label/first_name': 'first_name*',
    'BWF/form/contact/label/last_name': 'last_name*',
    'BWF/form/contact/label/email': 'email*',
    'BWF/form/contact/label/phone': 'phone*',
    'BWF/form/contact/label/street_number': 'street_number*',
    'BWF/form/contact/label/address_additional_info': 'address_additional_info*',
    'BWF/form/contact/label/post_code': 'post_code*',
    'BWF/form/contact/label/city': 'city*',
    'BWF/form/contact/label/country': 'country*',
    'BWF/form/contact/label/state': 'state*',
    'BWF/form/contact/label/middlename': 'middlename*',
    'BWF/form/contact/placeholder/subject': 'subject*',
    'BWF/form/contact/placeholder/message': 'message*',
    'BWF/form/contact/placeholder/company': 'company*',
    'BWF/form/contact/placeholder/salutation': 'salutation*',
    'BWF/form/contact/placeholder/title': 'title*',
    'BWF/form/contact/placeholder/first_name': 'first_name*',
    'BWF/form/contact/placeholder/last_name': 'last_name*',
    'BWF/form/contact/placeholder/email': 'email*',
    'BWF/form/contact/placeholder/phone': 'phone*',
    'BWF/form/contact/placeholder/street_number': 'street_number*',
    'BWF/form/contact/placeholder/address_additional_info': 'address_additional_info*',
    'BWF/form/contact/placeholder/post_code': 'post_code*',
    'BWF/form/contact/placeholder/city': 'city*',
    'BWF/form/contact/placeholder/country': 'country*',
    'BWF/form/contact/placeholder/state': 'state*',
    'BWF/form/contact/placeholder/middlename': 'middlename*',
    'BWF/form/contact/push/newsletterSuccess': 'Newsletter erfolgreich Abonniert *',
    'BWF/form/contact/push/newsletterError': 'Newsletter konnte nicht abonniert werden *',
    'BWF/form/contact/push/contactFormSuccess': 'Kontaktanfrage erfolgreich übermittelt *',
    'BWF/form/contact/push/contactFormError': 'Es gab einen fehler beim versenden der Kontaktanfrage *',
    /* COMPONENTS */
    'BWF/components/newsletter/newsletterConsent':
        'Ich willige ein, dass die BRITA SE, DE-65232 Taunusstein, Deutschland, mich per E-Mail mit persönlich auf mich zugeschnittenen Informationen, Angeboten und Vorteilsaktionen zu BRITA-Produkten und -Dienstleistungen (Wasserfiltersysteme, Wasserspender, Zubehör und Services) kontaktiert. Ich kann diese Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen.  Hinweise zum Datenschutz finde ich {{ dataPrivacyPage | hier }}.*',
    'BWF/components/newsletter/submit': 'Jetzt Anmelden*',
    'BWF/components/newsletter/personalData': 'Freiwillige persönliche Daten*',
    'BWF/components/newsletter/doi/personalHint':
        'Wenn du uns deine Andrede, sowie Namen mitteilst können wir Dich persönlicher ansprechen.*',
    'BWF/components/newsletter/doi/birthdayHint':
        'Wenn Du uns dein Geburtstag mitteilst, können wir dir zu deinem Geburtstag ein kleines Geschenk via E-Mail zukommen lassen. *',
    'BWF/components/newsletter/doi/form/sendButtonLabel': 'Absenden *',
    'BWF/components/newsletter/doi/form/salutationLabel': 'Anrede*',
    'BWF/components/newsletter/doi/form/firstnameLabel': 'Vorname*',
    'BWF/components/newsletter/doi/form/firstnamePlaceholder': 'Vorname eingeben...*',
    'BWF/components/newsletter/doi/form/lastnameLabel': 'Nachname*',
    'BWF/components/newsletter/doi/form/lastnamePlaceholder': 'Nachname eingeben...*',
    'BWF/components/newsletter/doi/form/birthdateLabel': 'Geburtstag*',
    'BWF/components/newsletter/doi/form/birthdatePlaceholder': 'Geburtstag eingeben...*',
    'BWF/components/newsletter/doi/form/successMessage': 'Daten aktualisiert!*',
    'BWF/components/newsletter/doi/form/errorMessage': 'Ein Fehler ist aufgetreten. *',
    'BWF/components/newsletter/doi/form/noDataErrorMessage': 'Bitte das Formular ausfüllen. *',

    'BWF/components/newsletter/unsubscribe/form/sendButtonLabel': 'Jetzt abmelden *',
    'BWF/components/newsletter/unsubscribe/form/emailLabel': 'E-Mail*',
    'BWF/components/newsletter/unsubscribe/form/otherReasonLabel': 'Anderer Grund*',
    'BWF/components/newsletter/unsubscribe/form/reasonMessagePlaceholder': 'Nachricht eingeben...*',
    'BWF/components/newsletter/unsubscribe/form/errorMessage': 'Ein Fehler ist aufgetreten.',

    'BWF/components/story-overview/filterBy': 'Nach Thema filtern:*',
    'BWF/components/story-overview/seenArticleLeading': 'Sie haben*',
    'BWF/components/story-overview/outOf': 'von*',
    'BWF/components/story-overview/seenArticleTailing': 'Artikel gesehen*',
    'BWF/components/story-overview/displayMore': 'Mehr anzeigen*',

    'BWF/components/faq-with-directory/directoryHeadline': 'Inhalte*',

    'BWF/shop/addToCart/itemAdded': 'Hinzugefügt*',
    'BWF/shop/addToCart/addToCart': 'Hinzufügen*',
    'BWF/shop/addToCart/notifyMe': 'Benachrichtige mich*',
    'BWF/shop/addToCart/addedToCartPush': 'hinzugefügt*',
    'BWF/shop/addToCart/addToCartAvailabilityError': 'not available*',

    'BWF/shop/productListing/loadMore': 'Mehr laden*',
    'BWF/shop/productListing/noProducts': 'Keine Produkte gefunden 😔*',
    /* NEWSLETTER */
    'BWF/newsletter/doi/technicalErrorMessage': 'Es ist ein Fehler aufgetreten*',
    /* MIGRATION */
    'BWF/migration/yource/buttonLabel': 'Yource Account migrieren*',
    /* SHOP*/
    'BWF/shop/shared/singleUnitPrice': 'Einzelpreis *',
    'BWF/shop/shared/articlePlural': 'Artikel *',
    'BWF/shop/shared/articleSingular': 'Artikel *',
    'BWF/shop/shared/learnMore': 'Learn More*',
    'BWF/shop/shared/alreadyRegistered': 'Du hast bereits einen Kundenkonto?*',
    'BWF/shop/shared/continue': 'Weiter*',
    'BWF/shop/shared/edit': 'Bearbeiten*',
    'BWF/shop/shared/createAddress': 'Adresse erstellen*',
    'BWF/shop/shared/change': 'Ändern*',
    'BWF/shop/shared/cancel': 'Abbrechen*',
    'BWF/shop/shared/save': 'Speichern*',
    'BWF/shop/shared/emailAddress': 'E-Mail-Adresse:*',
    'BWF/shop/shared/emailAddressUpdateInProgress': 'E-Mail-Adresse in Aktualisierung:*',
    'BWF/shop/shared/shippingAddress': 'Lieferadresse: *',
    'BWF/shop/shared/shippingAddressEdit': 'Adresse bearbeiten*',
    'BWF/shop/shared/billingAddress': 'Rechnungsadresse: *',
    'BWF/shop/shared/billingAddressEdit': 'Adresse bearbeiten*',
    'BWF/shop/shared/shippingAddressLabel': 'Lieferadresse*',
    'BWF/shop/shared/billingAddressLabel': 'Rechnungsadresse*',
    'BWF/shop/shared/addressLabel': 'Adresse *',
    'BWF/shop/shared/mandatoryFieldsLabel': 'Die mit einem Stern (*) markierten Felder sind Pflichtfelder.*',
    'BWF/shop/shared/mandatoryFieldsLabelGuestCheckout':
        '** Die mit zwei Sternen markierten Felder sind Pflichtfelder.*',
    'BWF/shop/shared/saveChangesLabel': 'Änderungen speichern*',
    'BWF/shop/shared/tableQuantity': 'Anzahl *',
    'BWF/shop/shared/loginNow': 'Jetzt einloggen*',
    'BWF/shop/shared/address': 'Deine Adresse*',
    'BWF/shop/shared/cartHeadline': 'Warenkorb*',
    'BWF/shop/shared/backToShop': 'Weiter einkaufen*',
    // 'BWF/shop/shared/articleCount': '<b data-html>Artikel:</b> {{count}}*',
    'BWF/shop/shared/articleCount': 'Artikel:*',
    // ###
    'BWF/shop/shared/addressForm/identicalToBillingAddress': 'Lieferadresse ist identisch*',
    'BWF/shop/shared/addressForm/differentShippingAddressLabel':
        'Die Lieferadresse weicht von der Rechnungsadresse ab*',
    'BWF/shop/shared/addressForm/salutationLabel': 'Anrede*',
    'BWF/shop/shared/addressForm/chooseSalutationLabel': 'Anrede auswählen...*',
    'BWF/shop/shared/addressForm/firstNameLabel': 'Vorname*',
    'BWF/shop/shared/addressForm/firstNamePlaceholder': 'Vornamen eingeben...*',
    'BWF/shop/shared/addressForm/lastNameLabel': 'Nachname*',
    'BWF/shop/shared/addressForm/lastNamePlaceholder': 'Nachnamen eingeben...*',
    'BWF/shop/shared/addressForm/streetAndHouseNoLabel': 'Straße und Hausnummer*',
    'BWF/shop/shared/addressForm/streetAndHouseNoPlaceholder': 'Straße und Hausnummer eingeben...*',
    'BWF/shop/shared/addressForm/eMailAddressLabel': 'E-Mail-Adresse*',
    'BWF/shop/shared/addressForm/eMailAddressPlaceholder': 'E-Mail-Adresse eingeben...*',
    'BWF/shop/shared/addressForm/companyLabel': 'Unternehmen**',
    'BWF/shop/shared/addressForm/companyPlaceholder': 'Unternehmen eingeben...*',
    'BWF/shop/shared/addressForm/postalCodeLabel': 'PLZ*',
    'BWF/shop/shared/addressForm/postalCodePlaceholder': 'PLZ eingeben...*',
    'BWF/shop/shared/addressForm/cityLabel': 'Ort*',
    'BWF/shop/shared/addressForm/cityPlaceholder': 'Ort eingeben...*',
    'BWF/shop/shared/addressForm/addressSuffixLabel': 'Adresszusatz*',
    'BWF/shop/shared/addressForm/addressSuffixPlaceholder': 'Adresszusatz eingeben...*',
    'BWF/shop/shared/addressForm/countryLabel': 'Land*',
    // ###
    'BWF/shop/account/grid/overview': 'Übersicht*',
    'BWF/shop/account/grid/myProfile': 'Mein Profil*',
    'BWF/shop/account/grid/addressBook': 'Meine Adressen*',
    'BWF/shop/account/grid/orderHistory': 'Bestellungen*',
    'BWF/shop/account/grid/payment': 'Zahlungsmethoden*',
    'BWF/shop/account/grid/subscriptions': 'Abonnements*',
    'BWF/shop/account/grid/logout': 'Ausloggen*',
    'BWF/shop/account/overview/newsletterRegistrationButtonLabel': 'Newsletter abonnieren *',
    'BWF/shop/account/overview/newsletterUnsubscribeButtonLabel': 'Newsletter kündigen *',
    // ### order history
    'BWF/shop/account/orderHistory/itemNumber': 'Art. Nr.*',
    'BWF/shop/account/orderHistory/orderNumber': 'Bestellnummer: *',
    'BWF/shop/account/orderHistory/orderDetails': 'Bestelldetails*',
    'BWF/shop/account/orderHistory/finalizePayment': 'Zahlung abschließen*',
    'BWF/shop/account/orderHistory/dateOfOrder': 'Bestelldatum*',
    'BWF/shop/account/orderHistory/status': 'Versandstatus*',
    'BWF/shop/account/orderHistory/paymentStatus': 'Zahlungsstatus*',
    'BWF/shop/account/orderHistory/paymentMethod': 'Zahlungsart*',
    'BWF/shop/account/orderHistory/shippingMethod': 'Versandart*',
    'BWF/shop/account/orderHistory/unitPrice': 'Stückpreis: *',
    'BWF/shop/account/orderHistory/productLabel': 'Produkt *',
    'BWF/shop/account/orderHistory/total': 'Summe *',
    'BWF/shop/account/orderHistory/positionPrice': 'Zwischensumme *',
    'BWF/shop/account/orderHistory/promotionLabel': 'Gutschein*',
    'BWF/shop/account/orderHistory/shippingCost': 'Versandkosten *',
    'BWF/shop/account/orderHistory/grandTotal': 'Gesamtsumme *',
    'BWF/shop/account/orderHistory/returnArticles': 'Zurückzugebende Artikel:*',
    'BWF/shop/account/orderHistory/returnHeadline': 'Retoure erstellen*',
    'BWF/shop/account/orderHistory/returnReasons': 'Wähle der Grund für die Rückgabe:*',
    'BWF/shop/account/orderHistory/parcelCount': 'Wähle die Anzahl der zurückzugebenden Pakete:*',
    'BWF/shop/account/orderHistory/reasonLabel': 'Grund*',
    'BWF/shop/account/orderHistory/parcelCountLabel': 'Anzahl Pakete:*',
    'BWF/shop/account/orderHistory/message': 'Nachricht*',
    'BWF/shop/account/orderHistory/cancellation': 'Abbrechen *',
    'BWF/shop/account/orderHistory/confirmReturn': 'Retoure bestätigen *',
    'BWF/shop/account/orderHistory/failed': 'fehlgeschlagen *',
    'BWF/shop/account/orderHistory/myOrders': 'Meine Bestellungen *',
    'BWF/shop/account/orderHistory/from': 'von*',
    'BWF/shop/account/orderHistory/orders': 'Bestellungen*',
    'BWF/shop/account/orderHistory/showMoreOrders': 'Mehr Bestellungen anzeigen*',
    'BWF/shop/account/orderHistory/searchAllOrdersPlaceholder': 'Alle Bestellungen durchsuchen *',
    'BWF/shop/account/orderHistory/warrantyHeadline': 'Garantie erstellen*',
    'BWF/shop/account/orderHistory/warrantyReasons': 'Wähle der Grund für die Rückgabe (Garantie):*',
    'BWF/shop/account/orderHistory/warrantyUploadHeadline':
        'Bitte Bilder oder Videos des Produkts hochladen, die das Problem deutlich zeigen:*',
    'BWF/shop/account/orderHistory/warrantyUploadFormat': 'Datei-Formate: png, jpeg, heic, pdf, mov, mpeg, mp4, avi*',
    'BWF/shop/account/orderHistory/warrantyUploadSize': 'Max. Dateigröße: Bilder 30MB, Videos 128MB*',
    'BWF/shop/account/orderHistory/warrantyUploadFiles': 'Dateien hochladen*',
    'BWF/shop/account/orderHistory/warrantyUploadMoreFiles': 'Weitere Dateien hochladen*',
    'BWF/shop/account/orderHistory/warrantyButtonLabel': 'Kundenservice kontaktieren*',

    'BWF/shop/account/orderHistory/warrantyClaim/videoFileSizeError': 'Die Videodatei ist zu groß*',
    'BWF/shop/account/orderHistory/warrantyClaim/otherFileSizeError': 'Die Datei ist zu groß*',
    'BWF/shop/account/orderHistory/warrantyClaim/maxFileQty': 'ist die maximale Anzahl an erlaubter Dateien*',
    'BWF/shop/account/orderHistory/warrantyClaim/notAddedFiles': 'Dateien wurden nicht hinzugefügt*',
    // ###
    'BWF/shop/checkout/includes': 'zzgl*',
    'BWF/shop/checkout/tax': 'MwSt.*',
    'BWF/shop/checkout/plusTax': 'inkl. MwSt.*',
    'BWF/shop/checkout/addressForm/headline': 'Versandinformation*',
    'BWF/shop/checkout/addressForm/alreadyRegisteredCTA': 'Du hast bereits einen Kundenkonto?*',
    'BWF/shop/checkout/addressForm/loginAccount': 'In Kundenkonto einloggen*',
    'BWF/shop/checkout/addressForm/personals': 'Persönliche Information*',
    'BWF/shop/checkout/addressForm/altAddress': 'Abweichende Lieferadresse*',
    'BWF/shop/checkout/addressForm/continueToCheckout': 'Weiter zur Kasse*',
    'BWF/shop/checkout/addressForm/emailAddressLabel': 'Email-Adresse*',
    'BWF/shop/checkout/addressForm/emailAddressChangeLabel': 'E-Mail Adresse ändern*',
    'BWF/shop/checkout/addressForm/emailAddressPlaceholder': 'E-Mail Adresse eingeben...*',
    'BWF/shop/checkout/addressForm/notifications/forceDeliveryAddress':
        'Lieferung ist an dieses Land nicht möglich, wähle bitte eine Lieferadresse aus einem gültigen Land aus*',

    'BWF/shop/checkout/cart/cartHeadline': 'Warenkorb*',
    'BWF/shop/checkout/cart/tableProduct': 'Produkt*',
    'BWF/shop/checkout/cart/tableTotal': 'Summe*',
    'BWF/shop/checkout/cart/summary': 'Zusammenfassung*',
    'BWF/shop/checkout/cart/subtotal': 'Zwischensumme*',
    'BWF/shop/checkout/cart/total': 'Gesamtsumme*',
    'BWF/shop/checkout/cart/voucher': 'Gutschein*',
    'BWF/shop/checkout/cart/removeVoucher': 'Entfernen*',
    'BWF/shop/checkout/cart/shippingCosts': 'Versandkosten*',
    'BWF/shop/checkout/cart/taxes': 'MwSt*',
    'BWF/shop/checkout/cart/continueToCheckout': 'Weiter zur Kasse*',
    'BWF/shop/checkout/cart/toCheckout': 'Zur Kasse*',
    'BWF/shop/checkout/cart/backToShop': 'Zurück zum Shop*',
    'BWF/shop/checkout/cart/emptyLabel': 'Keine Artikel im Warenkorb*',

    'BWF/shop/checkout/cart/exchangeBoxItem/subscriptionHint': 'Tauschbox-Service ist ein Abo*',
    'BWF/shop/checkout/cart/exchangeBoxItem/articleNumber': 'Art. Nr: {{ productNumber }}*',
    'BWF/shop/checkout/cart/exchangeBoxItem/fillingLabel': 'Füllung*',
    'BWF/shop/checkout/cart/exchangeBoxItem/quantifierLabel': 'x {{ amountCylinders }}*',

    'BWF/shop/checkout/complete/paymentMethod': 'Zahlungsart:*',
    'BWF/shop/checkout/complete/shippingMethod': 'Versandart:*',
    'BWF/shop/checkout/complete/shippingTime': 'Lieferzeit:*',
    'BWF/shop/checkout/complete/rightOfWithdrawal': 'Bitte beachte die Widerrufsbelehrung*',
    'BWF/shop/checkout/complete/privacyPolicyNotice':
        'Ich habe die Datenschutzbestimmungen zur Kenntnis genommen und die AGB gelesen und bin mit ihnen einverstanden.*',
    'BWF/shop/checkout/complete/rightOfWithdrawalNoCheckbox': 'Widerrufsbelehrung *',
    'BWF/shop/checkout/complete/privacyPolicyNoticeNoCheckbox':
        'Mit dem Kauf akzeptiere ich die Datenschutzbestimmungen sowie die AGB und bin mit ihnen einverstanden.*',
    'BWF/shop/checkout/complete/confirmPurchase': 'Zahlungspflichtig bestellen*',
    'BWF/shop/checkout/complete/completeOrderHeadline': 'Bestellabschluss*',
    'BWF/shop/checkout/mybrita/mybritaHeadline': 'Noch kein MyBRITA-Konto?*',
    'BWF/shop/checkout/mybrita/benefits': 'Jetzt kostenlos registrieren und von diesen Vorteilen profitieren:*',
    'BWF/shop/checkout/mybrita/benefit1': 'Zeitsparend bestellen und nachbestellen*',
    'BWF/shop/checkout/mybrita/benefit2': 'Bequem persönliche Daten verwalten*',
    'BWF/shop/checkout/mybrita/benefit3': 'Praktischer Zugriff auf Ihre registrierten Produkte*',
    'BWF/shop/checkout/mybrita/registerNow': 'Jetzt bei MyBRITA registrieren!*',
    'BWF/shop/checkout/mybrita/loginToMybrita': 'Bei MyBRITA anmelden*',
    'BWF/shop/checkout/mybrita/createAccount': 'Kundenkonto anlegen*',
    'BWF/shop/checkout/mybrita/guestProceed': 'Als Gast zur Kasse*',
    'BWF/shop/checkout/mybrita/notReadyToRegister': 'Noch nicht bereit, ein Konto anzulegen?*',
    'BWF/shop/checkout/promo-code/headline': 'Gutschein verwenden*',
    'BWF/shop/checkout/promo-code/add': 'Hinzufügen*',
    'BWF/shop/checkout/promo-code/inputLabel': 'Gutschein-Code eingeben*',
    'BWF/shop/checkout/promo-code/inputPlaceholder': 'Code eingeben*',
    'BWF/shop/checkout/promo-code/existingCode':
        'Um einen anderen Gutscheincode anzuwenden, musst du zunächst den aktuell verwendeten Gutschein löschen*',
    'BWF/shop/checkout/success/orderMissing': 'ORDER MISSING*',
    'BWF/shop/checkout/success/orderReceived': 'Deine Bestellung ist eingegangen*',
    'BWF/shop/checkout/success/myBritaLinkLabel': 'MyBRITA*',
    'BWF/shop/checkout/success/confirmationEnRoute': 'Du erhältst in Kürze eine Email mit der Bestätigung.*',
    'BWF/shop/checkout/success/orderNumberLabel': 'Bestellnummer*',
    'BWF/shop/checkout/success/shippingDate': 'Liefertermin*',

    'BWF/shop/checkout/mybrita/myBritaOnlyHeadline': 'Leider nicht möglich*',
    'BWF/shop/checkout/mybrita/myBritaOnlyDescription':
        'Ihr Warenkorb enthält Produkte die nur für registrierte myBRITA Kunden erhältlich sind *',

    // ###
    'BWF/shop/productDetailPage/productNumber': 'Art.-Nr:*',
    'BWF/shop/productDetailPage/deliveryTime': 'Auf Lager: In {{ deliveryTime }} bei Dir*',
    'BWF/shop/productDetailPage/productNotAvailable': 'Produkt derzeit nicht vorrätig*',
    'BWF/shop/productDetailPage/productDeliveryScope': 'Im Lieferumfang enthalten sind:*',
    'BWF/shop/productDetailPage/priceSaving': 'Du sparst*',
    'BWF/shop/productDetailPage/plus': 'inkl.*',
    'BWF/shop/productDetailPage/addToCartLabel': 'Kaufen —*',
    'BWF/shop/productDetailPage/productDetails': 'Produktdetails:*',

    'BWF/shop/productDetailPage/subscriptionProduct/savingsBadgeLabel': 'spare*',
    'BWF/shop/productDetailPage/subscriptionProduct/singlePurchaseLabel': 'Einmaliger Kauf*',
    'BWF/shop/productDetailPage/subscriptionProduct/subscriptionLabel': 'Spar-abo*',
    'BWF/shop/productDetailPage/subscriptionProduct/subscriptionPriceBadgeLabel': 'Im spar-abo*',
    'BWF/shop/productDetailPage/subscriptionProduct/packSizeLabel': 'Packgröße*',
    'BWF/shop/productDetailPage/subscriptionProduct/recommendationLabel': 'Unsere Empfehlung*',
    'BWF/shop/productDetailPage/subscriptionProduct/subscriptionIntervalDropdownLabel': 'Lieferintervall*',
    'BWF/shop/productDetailPage/subscriptionProduct/subscriptionModalLinkLabel': 'Wie funktioniert der Sparplan?*',

    'BWF/shop/productDetailPage/exchangeBox/configuratorHeadline': 'Stell deine Tauschbox zusammen:*',
    'BWF/shop/productDetailPage/exchangeBox/cylinderHeadline': 'Anzahl der Zylinder:*',
    'BWF/shop/productDetailPage/exchangeBox/cylinderQuestion': 'Wie viele Zylinder hast du zuhause?*',
    'BWF/shop/productDetailPage/exchangeBox/cylinderQuestionHint': 'Ein voller Zylinder muss in Gerät verbleiben*',
    'BWF/shop/productDetailPage/exchangeBox/summaryHeadline': 'Übersicht über deine erste Tauschbox*',
    'BWF/shop/productDetailPage/exchangeBox/cylinderAmountHint': 'Hinweis, dass 5 Zylinder zum Tauschbox gehören*',
    'BWF/shop/productDetailPage/exchangeBox/summaryTotalPrice':
        'Gesamtpreis deiner ersten Tauschbox mit {{ amountCylinders }} vollen Zylindern*',
    'BWF/shop/productDetailPage/exchangeBox/summaryCylinders': '{{ amountCylinders }}x CO2-Zylinder*',
    'BWF/shop/productDetailPage/exchangeBox/summaryDeposit': '{{ amountCylinders }}x {{depositLineItemLabel}}*',
    'BWF/shop/productDetailPage/exchangeBox/nextPrice': 'Jede weitere CO2 Tauschbox mit {{ nextAmount }} Zylindern*',
    'BWF/shop/productDetailPage/exchangeBox/withDeposit': 'einmalig Pfand*',
    'BWF/shop/productDetailPage/exchangeBox/additionalVAT': 'Alle Preise und Beträge inkl. MwSt.*',

    'BWF/shop/productCard/soldOutLabel': 'AUSVERKAUFT*',
    'BWF/shop/productCard/sale': 'DEAL*',
    'BWF/shop/productCard/newLabel': 'NEU*',
    'BWF/shop/productCard/topsellerLabel': 'BESTSELLER*',

    'BWF/shop/exchangeBox/priceCardExchangeBoxHint': 'für {{ amountCylinders }} Zylinder zzgl. Pfand*',

    'BWF/shop/product/specs/brita_product_data_safety_instructions': 'Sicherheitshinweise*',
    'BWF/shop/product/specs/brita_product_data_details_and_dimensions': 'Details & Dimensionen*',
    'BWF/shop/product/specs/brita_product_data_materials_and_maintenance': 'Materialien & Wartung*',
    'BWF/shop/product/specs/brita_product_data_compatibility': 'Kompatibilität*',
    'BWF/shop/product/specs/brita_product_data_technical_details': 'Technische Details*',
    'BWF/shop/product/specs/brita_product_data_installation_requirements': 'Installationsvoraussetzungen*',
    'BWF/shop/product/specs/brita_product_data_instructions': 'Hinweise*',
    'BWF/shop/product/specs/brita_product_data_nutritional_values': 'Nährwerte*',
    'BWF/shop/product/specs/brita_product_data_ingredients': 'Zutaten*',
    'BWF/shop/product/specs/brita_product_data_varieties_overview': 'Sorten-Überblick*',
    'BWF/shop/product/specs/brita_product_data_preparation': 'Zubereitung*',
    'BWF/shop/product/specs/cmsDownloadHeadline': 'CMS DOWNLOADS*',

    // ###
    'BWF/shop/account/orderDetails/orderDetailsHeadline': 'Bestellübersicht*',
    'BWF/shop/account/orderDetails/orderDetails': 'Bestelldetails*',
    'BWF/shop/account/orderDetails/returnedItems': 'Zurückzugebende Artikel*',
    'BWF/shop/account/orderDetails/refundedItems': 'Zurückgegebene Artikel*',
    'BWF/shop/account/orderDetails/orderNumber': 'Bestellnummer:*',
    'BWF/shop/account/orderDetails/orderActions': 'Bestellung verwalten*',
    'BWF/shop/account/orderDetails/articles': 'Artikel*',
    'BWF/shop/account/orderDetails/dateOfOrder': 'Bestelldatum *',
    'BWF/shop/account/orderDetails/status': 'Lieferstatus *',
    'BWF/shop/account/orderDetails/paymentStatus': 'Zahlungsstatus*',
    'BWF/shop/account/orderDetails/paymentMethod': 'Zahlungsart*',
    'BWF/shop/account/orderDetails/shippingMethod': 'Lieferart*',
    'BWF/shop/account/orderDetails/receiptInformations': 'Rechnungsinformation*',
    'BWF/shop/account/orderDetails/returnInformations': 'Information zur Retoure*',
    'BWF/shop/account/orderDetails/trackingInformations': 'Tracking-Informationen*',
    'BWF/shop/account/orderDetails/trackingCarrier': 'Carrier*',
    'BWF/shop/account/orderDetails/trackingId': 'Tracking-ID*',
    'BWF/shop/account/orderDetails/noTrackingId': 'folgt*',
    'BWF/shop/account/orderDetails/returnDate': 'Retour erstellt:*',
    'BWF/shop/account/orderDetails/returnLabel': 'Rücksendeetikett herunterladen*',
    'BWF/shop/account/orderDetails/startReturn': 'Retoure starten *',
    'BWF/shop/account/orderDetails/startCancellation': 'Bestellung abbrechen *',
    'BWF/shop/account/orderDetails/startWarranty': 'Garantie starten*',
    'BWF/shop/account/orderDetails/modal/confirmCancellation': 'Ok*',
    'BWF/shop/account/orderDetails/modal/declineCancellation': 'Nein*',
    'BWF/shop/account/orderDetails/modal/confirmReturn': 'Ok*',
    'BWF/shop/account/orderDetails/warrantyOf': 'Garantiefall für *',
    'BWF/shop/account/orderDetails/returnOf': 'Rückgabe für *',
    'BWF/shop/account/orderDetails/failed': 'fehlgeschlagen *',

    'BWF/shop/account/orderDetails/orderPayment/headline': 'Zahlung erneut durchführen*',
    'BWF/shop/account/orderDetails/orderPayment/errorHeadline': 'Zahlung fehgeschlagen*',
    'BWF/shop/account/orderDetails/orderPayment/errorMessage':
        'Leider gab es ein Problem mit der von dir gewählten Zahlungsmethode. Versuche bitte erneut, die Zahlung durchzuführen, und wähle ggf. eine andere Methode aus.*',
    'BWF/shop/account/orderDetails/orderPayment/updatePaymentMethod': 'Zahlungspflichtig bestellen*',
    'BWF/shop/account/orderDetails/orderPayment/notificationHeadline': 'Zahlung ist nicht abgebrochen*',
    'BWF/shop/account/orderDetails/orderPayment/notificationMessage':
        'Ihre Zahlung ist nicht abgebrochen. Kundenservice kontaktieren*',
    'BWF/shop/account/orderDetails/exchangeBox/exchangeBoxLink':
        'Alle Details zu deiner BRITA CO2-Tauschbox Service findest du in deinem {{ myBritaSubscriptionPage | myBRITA  }} Bereich*',
    'BWF/shop/account/orderDetails/exchangeBox/returnLabelTamNotification':
        'Der Kundendienst sendet Ihnen per E-Mail die Rücksendeetiketten zu.*',
    'BWF/shop/account/orderDetails/exchangeBox/returnLabelAvailabilityNotification':
        'Rücksendeetikett wird in Kürze verfügbar sein*',
    'BWF/shop/account/orderDetails/orderPayment/noPaymentMethodError': 'Es muss eine Zahlungsmethode gewählt werden.*',

    'BWF/shop/account/order/return/InformationHeadline': 'Information zur Retoure *',
    'BWF/shop/account/order/return/returnCreatedLabel': 'Retour erstellt: *',
    'BWF/shop/account/order/return/shippingTypeLabel': 'Versandart: *',
    'BWF/shop/account/order/return/shippingInformationHeadline': 'Shipping Type & Information*',

    'BWF/shop/account/orderCancel/modal/overviewButton': 'Zu meinen Bestellungen*',
    'BWF/shop/account/orderCancel/modal/shopButton': 'Zum Shop.*',

    // ###
    'BWF/shop/account/profile/personalDataLabel': 'Persönliche Daten*',
    'BWF/shop/account/profile/accessData': 'Zugangsdaten*',
    'BWF/shop/account/profile/changeEmailAddress': 'E-Mail Adresse ändern*',
    'BWF/shop/account/profile/changePassword': 'Passwort ändern*',
    'BWF/shop/account/profile/editSuccessMessage': 'Benutzerinformationen erfolgreich geändert*',
    'BWF/shop/account/addressBook/setDefaultShippingAddress': 'Als Standard-Lieferadresse festlegen *',
    'BWF/shop/account/addressBook/setDefaultBillingAddress': 'Als Standard-Rechnungsadresse festlegen *',
    'BWF/shop/account/addressBook/addressBookHeadline': 'Meine Adressen*',

    'BWF/shop/account/overview/myAddress': 'Meine Adresse*',
    'BWF/shop/account/overview/greeting': 'Willkommen bei MyBRITA*',
    'BWF/shop/account/overview/mybritaOpeningText':
        'Hier haben Sie direkten Zugriff auf Ihre Profilinformationen, hinterlegte Adressen und die Standard-Zahlungsart.*',
    'BWF/shop/account/overview/myProfile': 'Mein Profil*',
    'BWF/shop/account/overview/noValidCustomerData': 'Keine gültige Kundendaten vorhanden*',
    'BWF/shop/account/overview/editProfile': 'Profil bearbeiten*',
    'BWF/shop/account/overview/noAddressAvailable': 'Keine gültige Adresse vorhanden*',
    'BWF/shop/account/overview/changeAddress': 'Adresse ändern*',
    'BWF/shop/account/overview/createAddress': 'Adresse anlegen*',
    'BWF/shop/account/overview/orders': 'Bestellungen *',
    'BWF/shop/account/overview/ordersExplanationTextLabel':
        'Hier kannst du deine bisherigen Bestellungen einsehen und Rücksendungen einleiten.*',
    'BWF/shop/account/overview/navigateToOrderHistory': 'Zur Bestellübersicht*',
    'BWF/shop/account/overview/myProducts': 'Meine Produkte *',
    'BWF/shop/account/overview/productsExplanationTextLabel':
        'Hier kannst du deine registrierten Produkte einsehen, sowie ein neues Produkt registrieren.*',
    'BWF/shop/account/overview/navigateToProducts': 'Zur Produktübersicht*',
    'BWF/shop/account/overview/abonnementsLabel': 'Abonnements & Tauschbox*',
    'BWF/shop/account/overview/abonnementsExplanationTextLabel':
        'Hier kannst du deine Abonnements und hinterlegten Pfand einsehen.*',
    'BWF/shop/account/overview/abonnements': 'Abonnements verwalten*',

    'BWF/shop/account/profile/myProfile': 'Mein Profil*',
    'BWF/shop/account/profile/customerNo': 'Kundennummer*',

    'BWF/shop/account/subscriptions/headline': 'Abonnements & Tauschbox*',
    'BWF/shop/account/subscriptions/description':
        'Hier kannst du deine Tauschboxen, Abonnements und hinterlegten Pfand einsehen.*',
    'BWF/shop/account/subscriptions/depositTitle': 'Hinterlegter Pfand (Gesamt)*',
    'BWF/shop/account/subscriptions/contract/exchangeboxActions': 'Tauschbox verwalten*',
    'BWF/shop/account/subscriptions/contract/exchangeboxActionReturn': 'Tauschbox zurücksenden*',
    'BWF/shop/account/subscriptions/contract/exchangeboxActionCancel': 'Tauschbox kündigen*',
    'BWF/shop/account/subscriptions/contract/subscriptionActions': 'Abonnement verwalten*',
    'BWF/shop/account/subscriptions/contract/subscriptionLabel': 'Abo*',
    'BWF/shop/account/subscriptions/contract/subscriptionActionCancel': 'Abo kündigen*',
    'BWF/shop/account/subscriptions/contract/subscriptionChangePayment': 'Zahlungsmethode ändern*',
    'BWF/shop/account/subscriptions/contract/subscriptionChangeSuccess': 'Zahlungsmethode erfolgreich geändert *',
    'BWF/shop/account/subscriptions/contract/subscriptionChangeError': 'Zahlungsmethode nicht geändert *',
    'BWF/shop/account/subscriptions/contract/subscriptionActionAddressEdit': 'Lieferadresse bearbeiten*',
    'BWF/shop/account/subscriptions/contract/setAddressSuccess': 'Versandadresse erfolgreich geändert *',
    'BWF/shop/account/subscriptions/contract/cylinderLabel': 'Zylinder & Pfand*',
    'BWF/shop/account/subscriptions/contract/cylinderAtHome': 'Aktuell in Besitz:*',
    'BWF/shop/account/subscriptions/contract/contractDeposit': 'Pfand gesamt:*',
    'BWF/shop/account/subscriptions/contract/lastChangeLabel': 'Letzter Tausch*',
    'BWF/shop/account/subscriptions/contract/paymentLabel': 'Zahlungemethode*',
    'BWF/shop/account/subscriptions/contract/creditCardNumber': 'Endziffern ...{{creditCardNumber}}*',
    'BWF/shop/account/subscriptions/contract/statusCancelled': 'Gekündigt*',
    'BWF/shop/account/subscriptions/contract/statusActive': 'Aktiv*',
    'BWF/shop/account/subscriptions/contract/paymentUpdateRequested': 'Zahlungsaktualisierung angefordert*',
    'BWF/shop/account/subscriptions/contract/cylindersAtHomeInformation': '{{ amount }}x {{label}} (je {{price}})*',
    'BWF/shop/account/subscriptions/contract/paymentExpired': 'Zahlungsmethode ist abgelaufen*',
    'BWF/shop/account/subscriptions/contract/paymentExpiringSoon': 'Zahlungsmethode läuft bald ab*',
    'BWF/shop/account/subscriptions/contract/updatePaymentMethod': 'Jetzt Zahlungsart aktualisieren*',
    'BWF/shop/account/subscriptions/contract/nextDeliveryLabel': 'Nächste Lieferung*',
    'BWF/shop/account/subscriptions/contract/nextExecutionDate': 'Liefertermin:*',
    'BWF/shop/account/subscriptions/contract/costLabel': 'Kosten:*',
    'BWF/shop/account/subscriptions/contract/deliveryIntervalLabel': 'Lieferintervall*',
    'BWF/shop/account/subscriptions/contract/every': 'Alle*',
    'BWF/shop/account/subscriptions/contract/days': 'Tage*',
    'BWF/shop/account/subscriptions/contract/weeks': 'Wochen*',
    'BWF/shop/account/subscriptions/contract/months': 'Monate*',
    'BWF/shop/account/subscriptions/contract/years': 'Jahre*',
    'BWF/shop/account/subscriptions/modal/confirmCancellation': 'Tauschbox kündigen*',
    'BWF/shop/account/subscriptions/modal/confirmSubscriptionCancellation': 'Abonnement kündigen*',
    'BWF/shop/account/subscriptions/modal/confirmReturnHeadline': 'Ihr Abonnement wird aufgelöst*',
    'BWF/shop/account/subscriptions/modal/confirmReturnDescription':
        'Wenn sie jetzt die Bestellung abbrechen, wird ihr Abonnement aufgelöst. Wollen sie dennoch fortfahren?*',
    'BWF/shop/account/subscriptions/modal/abortCancellation': 'Ich habe es mir anders überlegt*',
    'BWF/shop/account/subscriptions/modal/backToMyBRITA': 'Zurück zu MyBRITA*',
    'BWF/shop/account/subscriptions/modal/confirmPaymentChange': 'Zahlungsmethode ändern*',
    'BWF/shop/account/subscriptions/modal/abortPaymentChange': 'Ich habe es mir anders überlegt*',

    'BWF/shop/orderLookup/headline': 'Bestelldetails einsehen*',
    'BWF/shop/orderLookup/myBRITAHeadline': 'Bei MyBRITA anmelden*',
    'BWF/shop/orderLookup/myBRITAHint': 'Logge dich in deinen MyBRITA Account ein um deine Bestellung einzusehen.*',
    'BWF/shop/orderLookup/myBRITASignInLabel': 'Jetzt anmelden*',
    'BWF/shop/orderLookup/forgotPasswordLabel': 'Passwort vergessen?*',
    'BWF/shop/orderLookup/showOrderLabel': 'Bestellung anzeigen*',
    'BWF/shop/orderLookup/guestLookupHeadline': 'Bestellung als Gast einsehen*',
    'BWF/shop/orderLookup/errorHeadline': 'Bestellung nicht gefunden*',
    'BWF/shop/orderLookup/errorText':
        'Überprüfe, ob du die richtige E-Mail-Adresse und Bestellnummer eingegeben hast.**',

    'BWF/shop/errors/ZIP_CODE_INVALID': 'This value is not a valid ZIP code for country {{ iso }}',
    'BWF/shop/errors/CHECKOUT__CART_DESERIALIZE_FAILED': 'Failed to deserialize cart',
    'BWF/shop/errors/CHECKOUT__CUSTOMER_NOT_LOGGED_IN': 'Customer is not logged in.',
    'BWF/shop/errors/CHECKOUT__INSUFFICIENT_PERMISSION': 'Insufficient permission.',
    'BWF/shop/errors/CHECKOUT__CART_INVALID_PAYMENT_ORDER_STORED':
        'Order payment failed but order was stored with id {{ orderId }}',
    'BWF/shop/errors/CHECKOUT__CART_ORDER_CONVERT_NOT_FOUND': 'Order {{ orderId }} could not be found.',
    'BWF/shop/errors/CHECKOUT__CART_INVALID': 'The cart is invalid, got {{ errorCount }} error(s): {{ errors }}',
    'BWF/shop/errors/CHECKOUT__CART_INVALID_LINE_ITEM_QUANTITY':
        'The quantity must be a positive integer. Given: "{{ quantity }}"',
    'BWF/shop/errors/CHECKOUT__CART_DELIVERY_POSITION_NOT_FOUND': 'Delivery with identifier {{ id }} not found.',
    'BWF/shop/errors/CHECKOUT__CART_LINE_ITEM_NOT_REMOVABLE': 'Line item with identifier {{ id }} is not removable.',
    'BWF/shop/errors/CHECKOUT__CART_LINE_ITEM_NOT_STACKABLE':
        'Line item with identifier "{{ id }}" is not stackable and the quantity cannot be changed.',
    'BWF/shop/errors/CHECKOUT__CART_LINE_ITEM_NOT_FOUND': 'Line item with identifier {{ id }} not found',
    'BWF/shop/errors/CART_LINE_ITEM_NOT_REMOVABLE_CODE': 'Line item with identifier {{ id }} is not removable',
    'BWF/shop/errors/CHECKOUT__CART_LINE_ITEM_TYPE_NOT_SUPPORTED': 'Line item type "{{ type }}" is not supported.',
    'BWF/shop/errors/CHECKOUT__CART_MISSING_LINE_ITEM_PRICE': 'Line item with identifier {{ id }} has no price.',
    'BWF/shop/errors/CART_INVALID_PRICE_DEFINITION_CODE': 'Provided price definition is invalid.',
    'BWF/shop/errors/CHECKOUT__CART_MIXED_LINE_ITEM_TYPE':
        'Line item with id {{ id }} already exists with different type {{ type }}.',
    'BWF/shop/errors/CHECKOUT__CART_PAYLOAD_KEY_NOT_FOUND':
        'Payload key "{{ key }}" in line item "{{ id }}" not found.',
    'BWF/shop/errors/CART_INVALID_PERCENTAGE_DISCOUNT_CODE':
        'Percentage discount {{ key }} requires a provided float value',
    'BWF/shop/errors/CHECKOUT__CART_DISCOUNT_TYPE_NOT_SUPPORTED':
        'Discount type "{{ type }}" is not supported for discount {{ key }}',
    'BWF/shop/errors/CHECKOUT__CUSTOMER_CHANGE_PAYMENT_METHOD_NOT_FOUND':
        'Change Payment to method {{ paymentMethodId }} not possible.',
    'BWF/shop/errors/CHECKOUT__ORDER_ORDER_DELIVERY_NOT_FOUND': 'Order delivery with id {{ id }} not found.',
    'BWF/shop/errors/CHECKOUT__ORDER_ORDER_TRANSACTION_NOT_FOUND': 'Order transaction with id {{ id }} not found.',
    'BWF/shop/errors/CHECKOUT__ORDER_PAYMENT_METHOD_NOT_AVAILABLE':
        'The payment method with id {{ id }} is not available.',
    'BWF/shop/errors/CHECKOUT__CODE_ALREADY_REDEEMED':
        'Promotion with code "{{ code }}" has already been marked as redeemed!',
    'BWF/shop/errors/CHECKOUT__INVALID_CODE_PATTERN': 'Invalid code pattern "{{ codePattern }}".',
    'BWF/shop/errors/product-stock-reached': 'The product {{ name }} is only available {{ quantity }} times',
    'BWF/shop/errors/promotion-not-found': 'Promotion with code {{ promotionCode }} not found!',
    'BWF/shop/errors/mixed-contract-product-types-in-cart':
        'You can only have one type of contract product in your cart. You already have a {{ contractProductType }} in your cart.',
    'BWF/shop/errors/subscriptions-guest-account':
        'Ihr Warenkorb enthält Produkte die nur für registrierte myBRITA Kunden erhältlich sind *',
    // success messages (might show up as an error)
    'BWF/shop/messages/promotion-discount-added': 'Gutschein angewendet!*',
};
