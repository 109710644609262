import { getProductUrl } from '@shopware-pwa/helpers-next';

const useMagnoliaShopPages = function () {
    const productPages = useState('productPages', () => ({}));

    /**
     *  getPdpUrlByProduct
     *
     * @param product product-ish Object to find product pages (or variant parents product pages in cms)
     * @param forcePathVariable optional, can be used to enforce a certain id as a part variable (cart links)
     * @returns {string}
     */
    const getPdpUrlByProduct = (product, forcePathVariable = null) => {
        // use product id as fallback when seoPathInfo is not available

        let pathVariable = '';
        const pages = productPages.value ?? {};

        if (Object.keys(pages).length === 0) {
            return '';
        }
        let productPage = pages?.find(page => page.productId === (product?.id ?? -1));
        if (!productPage) {
            // no direct id hit means we need shopwares seo url
            pathVariable = product?.seoUrls?.[0]?.seoPathInfo ? getProductUrl(product) : '';
        }
        if (!productPage) {
            // look for a variants parent
            productPage = pages?.find(page => page.productId === (product?.parentId ?? -1));
        }
        if (!productPage) {
            // look for set child parent
            productPage = pages?.find(page => page.productId === (product?.referencedId ?? -1));
        }
        if (!productPage) {
            // lookup configured default pdp
            productPage = pages?.find(page => page.defaultProductPage === true);
            // if no product page just search the page wirth the shortest url
            if (!productPage) {
                // helper var until proper flag for default
                pages?.forEach(page => {
                    if (!productPage || page?.productPageLinkUrl?.length < productPage?.productPageLinkUrl?.length) {
                        productPage = page;
                    }
                });
            }
            pathVariable = `${product?.id ?? product?.parentId ?? product?.referencedId ?? ''}`;
        }

        let pdpUrl = '';
        if (productPage) {
            // found pdp
            pdpUrl = productPage.productPageLinkUrl;
            if (!!forcePathVariable) {
                pdpUrl += `/${forcePathVariable}`;
            } else if (pathVariable) {
                pdpUrl += `/${pathVariable}`;
            }

            if (product.subscription?.id) {
                // add subscription query param
                pdpUrl += `?subscription=${product.subscription?.id}`;
            }
        }
        return pdpUrl.replaceAll('//', '/');
    };

    const getEShopSetUrlByProduct = product => {
        const setLookupProduct = { referencedId: product?.children[0].referencedId };

        const magnoliaProductUrl = getPdpUrlByProduct(setLookupProduct);
        return `${magnoliaProductUrl}`;

        // since we do not have setoptions id here we cannot provide a proper preselection via get paremter TBD
        // const setOptionIDs = product?.children
        //     ?.slice(1)
        //     .map(child => child?.id)
        //     .join(',');

        // return `${magnoliaProductUrl}?setOptions=${setOptionIDs}`;
    };

    return { productPages, getPdpUrlByProduct, getEShopSetUrlByProduct };
};

export default useMagnoliaShopPages;
