<template>
    <div class="product-stage grid grid-cols-1 lg:grid-cols-12">
        <!-- Image gallery / Slider -->
        <NuxtErrorBoundary>
            <ProductStageGallery></ProductStageGallery>
        </NuxtErrorBoundary>

        <div class="mt-4 lg:mt-0 lg:col-span-4 lg:col-start-8 relative">
            <!-- Product info -->
            <div class="relative" :class="{ 'animate-pulse pointer-events-none': isLoading }">
                <div class="lg:col-span-2">
                    <ProductBadge class="hidden lg:inline-flex lg:mb-4" />
                    <div v-if="product?.manufacturer?.name" class="font-medium text-sm mb-1">
                        {{ product?.manufacturer?.name }}
                    </div>
                    <div class="flex gap-x-5">
                        <h1 class="font-normal text-2xl text-black lg:text-3xl" v-html="productName"></h1>
                        <DiscountRateLabel
                            v-if="isMobile && hasBulkPrice && activeBulkChild.displayDiscountRate"
                            class="self-start mt-1"
                            :discount-rate="activeBulkChild.displayDiscountRate"
                        />
                    </div>

                    <div
                        v-if="productDataDeliveryNote"
                        class="mt-1 text-sm font-normal"
                        v-html="productDataDeliveryNote"
                    ></div>
                    <EShopSetPrice v-if="hasEShopSet" />
                    <SubscriptionProductPrice v-else-if="hasSubscriptionConfiguration && subscriptionChooserActive" />
                    <BulkProductPrice
                        v-else-if="hasBulkPrice"
                        class="mt-1 lg:mt-2"
                        :product="product"
                        :active-bulk-child="activeBulkChild"
                    />
                    <ProductPrice v-else class="mt-1 lg:mt-2" :product="product" />
                    <ProductDeliveryScope
                        v-if="deliveryScope"
                        class="mt-4 lg:mt-8"
                        :product-delivery-scope="deliveryScope"
                    />
                    <slot name="introText"></slot>
                    <slot name="howTo"></slot>
                </div>

                <div v-if="hasBulkPrice" class="mt-4 md:mt-6 space-y-2">
                    <CommonLabel path="BWF/shop/shared/tableQuantity"></CommonLabel>
                    <span>:</span>
                    <SelectField v-model="productQuantity" :options="bulkChooserOptions"></SelectField>
                </div>

                <div v-if="containsExchangeBox" class="flex flex-col gap-2">
                    <ExchangeBoxVariantConfigurator
                        class="mt-6 xl:mt-10"
                        @before-change="beforeVariantChange"
                        @change="handleVariantChange"
                    />
                    <ProductExchangeBoxInformation class="mt-8">
                        <template #depositInfo>
                            <slot name="depositInfo"></slot>
                        </template>
                        <template #priceInfo>
                            <slot name="priceInfo"></slot>
                        </template>
                    </ProductExchangeBoxInformation>
                </div>

                <ProductSubscriptionConfigurator
                    v-else-if="hasSubscriptionConfiguration"
                    :show-option-name="true"
                    :option-button-default="true"
                    @before-subscription-change="beforeVariantChange"
                    @change="handleVariantChange"
                />

                <ProductVariantConfigurator
                    v-else
                    class="mt-6"
                    :show-option-name="true"
                    :option-button-default="true"
                    @before-change="beforeVariantChange"
                    @change="handleVariantChange"
                />

                <EShopSetConfigurator v-if="hasEShopSet" class="mt-7 xl:mt-10" />

                <div class="mt-6">
                    <div class="flex flex-col gap-4 lg:gap-6">
                        <ProductAvailability :product="product" :is-available="isProductAvailable" />
                        <CommonLabel
                            v-if="containsExchangeBox"
                            class="text-medium-grey text-sm"
                            path="BWF/shop/productDetailPage/exchangeBox/additionalVAT"
                        ></CommonLabel>
                        <div v-if="isProductAvailable" ref="addToCartButton">
                            <AddToCartButton
                                :product="product"
                                :disabled="isLoading"
                                :product-quantity="productQuantity"
                                class="w-full btn btn-blue mt-auto"
                            >
                                <div class="flex items-center leading-5">
                                    <svg-icon name="Cart" class="w-4 h-4" />
                                    <CommonLabel
                                        class="ml-2"
                                        path="BWF/shop/productDetailPage/addToCartLabel"
                                    ></CommonLabel>
                                    &nbsp;
                                    <SharedPrice :value="totalPrice" />
                                </div>
                            </AddToCartButton>
                        </div>
                    </div>
                    <ShopBenefits v-if="marketSetup?.shopBenefits" class="mt-6 lg:mt-8" />
                </div>

                <!-- Description and details -->
                <div class="mt-10 lg:col-start-1 lg:col-span-2">
                    <div v-if="description" class="product-description-and-details">
                        <h3 class="text-sm font-medium">
                            <CommonLabel path="BWF/shop/productDetailPage/productDetails"></CommonLabel>
                        </h3>
                        <div class="mt-4">
                            <div class="prose" v-html="description" />
                        </div>
                    </div>
                    <p v-if="!hasHiddenMainProduct" class="mt-4 flex gap-2 text-sm text-black">
                        <span class="font-medium">
                            <CommonLabel path="BWF/shop/productDetailPage/productNumber"></CommonLabel>
                        </span>
                        <span class="font-normal">{{ product?.productNumber }}</span>
                    </p>
                </div>

                <div
                    v-if="isProductAvailable"
                    class="fixed inset-x-0 bottom-0 p-2 lg:py-5 shadow-3xl bg-white transition-transform z-[4]"
                    :class="[showStickyAddToCartButton && !isEditMode ? 'translate-y-0' : 'translate-y-[150%]']"
                >
                    <div class="container grid grid-cols-1 lg:grid-cols-12">
                        <div class="lg:col-span-4 lg:col-start-8">
                            <AddToCartButton
                                :product="product"
                                :product-quantity="productQuantity"
                                :disabled="isLoading"
                                class="w-full btn btn-blue mt-auto"
                            >
                                <div class="flex items-center leading-5">
                                    <svg-icon name="Cart" class="w-4 h-4" />
                                    <CommonLabel
                                        class="ml-2"
                                        path="BWF/shop/productDetailPage/addToCartLabel"
                                    ></CommonLabel>
                                    &nbsp;
                                    <SharedPrice :value="totalPrice" />
                                </div>
                            </AddToCartButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { Product } from '@shopware-pwa/types';
import { getProductUrl, getTranslatedProperty } from '@shopware-pwa/helpers-next';
import AddToCartButton from '~/templates/partials/header/AddToCartButton.vue';
import SvgIcon from '~/templates/elements/SvgIcon.vue';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import ProductBadge from '~/templates/components/shop/product/ProductBadge.vue';
import { useProductPrice } from '@shopware-pwa/composables-next';
import ShopBenefits from '~/templates/components/shop/product/ShopBenefits.vue';
import EShopSetConfigurator from '~/templates/components/shop/product/EShopSets/EShopSetConfigurator.vue';
import { useProductBulkPrices } from '~/composables/shop/useProductBulkPrices';
import { useProductEShopSetConfigurator } from '~/composables/shop/useProductEShopSetConfigurator';
import ExchangeBoxVariantConfigurator from '~/templates/components/shop/product/ExchangeBoxVariantConfigurator.vue';
import { useExchangeBox } from '~/composables/shop/useExchangeBoxProduct';
import ProductSubscriptionConfigurator from '~/templates/components/shop/product/ProductSubscriptionConfigurator.vue';
import SubscriptionProductPrice from '~/templates/components/shop/product/subscriptionProductPrice.vue';
import type { Ref } from 'vue';
import { isEditMode as evaluateEditMode } from '~/utils/content/magnolia';
import EShopSetPrice from '~/templates/components/shop/product/EShopSets/EShopSetPrice.vue';
import useItemTracking from '~/composables/tracking/useItemTracking';
import {
    type SubscriptionConfiguration,
    useProductSubscriptionConfigurator,
} from '~/composables/shop/useProductSubscriptionConfigurator';
const isEditMode = evaluateEditMode();

const { product } = useProduct();

const { price } = useProductPrice(product);
import SelectField from '~/templates/elements/form/SelectField.vue';
import BulkProductPrice from '~/templates/components/shop/product/BulkProductPrice.vue';
import DiscountRateLabel from '~/templates/elements/DiscountRateLabel.vue';
import useMagnoliaContent from '~/composables/useMagnoliaContent';
const { isMobile } = useDevice();

const { hasBulkPrice, bulkChooserOptions, activeBulkChild, productQuantity } = useProductBulkPrices(product);
const { query, fullPath } = useRoute();
const { pathVariable } = useMagnoliaContent();

const { marketSetup } = useMarketSetup();
const querySetOptions = ((query?.setOptions ?? '') as string).split(',').filter(option => option.length > 0);

const {
    eShopSet,
    hasEShopSet,
    eshopSetAvailable,
    eShopSetCurrentSelection,
    isLoadingUpdatedEShopSet,
    hasHiddenMainProduct,
} = await useProductEShopSetConfigurator(querySetOptions);

const { containsExchangeBox, exchangeBoxTotal } = await useExchangeBox();

const isLoading: Ref<boolean> = inject('isLoading', ref(false));

watch(isLoadingUpdatedEShopSet, () => {
    isLoading.value = isLoadingUpdatedEShopSet.value;
});

const addToCartButton: Ref<HTMLElement | null> = ref(null);

const showStickyAddToCartButton = ref(false);

useIntersectionObserver(addToCartButton, ([{ isIntersecting }]) => {
    const positionY = addToCartButton.value?.getBoundingClientRect?.()?.y ?? 0;
    showStickyAddToCartButton.value = !isIntersecting && positionY > 0;
});

const {
    hasSubscriptionConfiguration,
    subscriptionChooserActive,
    subscriptionTotalPrice,
    activeSubscriptionIntervalId,
    intervalConfigurations,
} = useProductSubscriptionConfigurator(product);

/**
 * Reactive Product Data
 */

const productName = computed(() => getTranslatedProperty(product.value.translated, 'name'));
const productDataDeliveryNote = computed(() =>
    //@ts-ignore
    getTranslatedProperty(product.value?.translated?.customFields, 'brita_product_data_delivery_note')
);
const deliveryScope = computed(() =>
    //@ts-ignore
    getTranslatedProperty(product.value?.translated?.customFields, 'brita_product_data_delivery')
);
const description = computed(() => getTranslatedProperty(product.value, 'description'));

const totalPrice = computed(() => {
    if (hasBulkPrice.value) {
        return activeBulkChild.value?.totalPrice;
    }
    if (hasSubscriptionConfiguration.value && subscriptionChooserActive.value) {
        return subscriptionTotalPrice.value;
    }
    if (containsExchangeBox.value) {
        return exchangeBoxTotal.value;
    }
    if (hasEShopSet.value && eShopSet.value) {
        return eShopSet.value?.eshopSetPriceTotal;
    }
    return price?.value?.totalPrice;
});

const isProductAvailable = computed(() => (!hasEShopSet.value && product?.value?.available) || eshopSetAvailable.value);

const beforeVariantChange = async () => {
    isLoading.value = true;
};

const emit = defineEmits(['variantChange']);
const handleVariantChange = async (changedProduct: Partial<Product>) => {
    emit('variantChange', changedProduct);
};

const { trackItemView } = useItemTracking({ item: product, addToCartQuantity: productQuantity.value });

onMounted(() => {
    watch([subscriptionChooserActive, activeSubscriptionIntervalId, product, eShopSetCurrentSelection], () => {
        /**
         * UPDATE PDP URLS with vanilla history API as any changes to the path via vue router will cause NUXT to reload page data
         *
         * reloads do not mak eany sense as data is already loaded at this point.
         * just need representation in the URL
         */
        // extract first segment of fullUrl to get the vanillaurl without a variant
        const [currentPdpUrl] = fullPath.split(pathVariable.value);
        const urlParams = new URLSearchParams();
        if (eShopSetCurrentSelection.value?.length > 0) {
            // eshop set options
            urlParams.set('setOptions', eShopSetCurrentSelection.value.join(','));
            // eshopsets shall never add the variant to the url fix pdpUrl / just updates setOptions
            history?.replaceState(null, '', `${currentPdpUrl}?${urlParams.toString()}`);
        } else {
            // evaluate new variant pdp segment
            const changedPathVariable = product?.value?.seoUrls?.[0]?.seoPathInfo
                ? getProductUrl(product?.value)
                : `/${product?.value?.id}`;
            // fullpath contains query and will break eventually
            const updatedPdpUrl = `${currentPdpUrl}${changedPathVariable}${subscriptionChooserActive.value ? `?subscription=${activeSubscriptionIntervalId?.value}` : ``}`;
            history?.replaceState(null, '', updatedPdpUrl);
        }
    });

    watch(activeSubscriptionIntervalId, () => {
        let changedSubscriptionProductId = intervalConfigurations.value?.find(
            (configurationToLoad: SubscriptionConfiguration) =>
                configurationToLoad.intervalConfigurationId === activeSubscriptionIntervalId.value
        )?.productId;
        if (changedSubscriptionProductId !== product.value?.id) {
            beforeVariantChange();
            emit('variantChange', { id: changedSubscriptionProductId });
        }
    });

    trackItemView();
});
</script>

<style>
.product-stage {
    .product-variant-configurator {
        @apply gap-10;
    }

    .product-description-and-details {
        ul {
            list-style-image: url('~/assets/sprite/svg/Checkmark.svg');
        }

        li {
            @apply pl-2;
        }
    }
}
</style>
