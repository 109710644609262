import type { Cart, Product } from '@shopware-pwa/types';
import { invokePost } from '@shopware-pwa/api-client';
import type { Ref } from 'vue';
import { _useContext } from '~/composables/shop/_useContext';

export type SubscriptionConfiguration = {
    intervalConfigurationId?: string;
    productId?: string;
    label?: string;
    description?: string;
    subscriptionPrice?: {
        extensions?: {
            netPrice?: {
                unitNetPrice?: number;
                totalNetPrice?: number;
                apiAlias?: string;
            };
        };
        unitPrice?: number;
        quantity?: number;
        totalPrice?: number;
        calculatedTaxes?: [];
        taxRules?: [];
        referencePrice?: null;
        listPrice?: {
            price?: number;
            discount?: number;
            percentage?: number;
            apiAlias?: string;
        };
        regulationPrice?: null;
        displayDiscountRate?: number;
        totalPriceWithoutDiscount?: number;
        apiAlias?: string;
    };
    apiAlias?: string;
};

export function useProductSubscriptionConfigurator(product: Ref<Product>) {
    const { apiInstance } = useShopwareContext();
    const { refreshCart } = useCart();
    const { query } = useRoute();
    //@ts-ignore
    const intervalConfigurations = computed(() => {
        //@ts-ignore
        return product.value?.extensions?.subscription?.intervalConfigurations;
    });
    const hasSubscriptionConfiguration = computed(() => {
        return intervalConfigurations.value?.length > 0;
    });

    const subscriptionChooserOptions = computed(() => {
        // @ts-ignore extension
        return intervalConfigurations.value?.map((intervalConfiguration: SubscriptionConfiguration) => ({
            value: intervalConfiguration.intervalConfigurationId,
            label: intervalConfiguration.label,
        }));
    });

    const subscriptionChooserActive: Ref<boolean | null> = _useContext('subscriptionChooserActive');
    if (!subscriptionChooserActive.value) {
        // init injected ui flag as false to initialize UI state
        subscriptionChooserActive.value = !!query.subscription;
    }

    const activeSubscriptionIntervalId: Ref<string> = _useContext('activeSubscriptionIntervalId');
    if (!activeSubscriptionIntervalId.value && query.subscription) {
        activeSubscriptionIntervalId.value = `${query.subscription}`;
    }

    if (!activeSubscriptionIntervalId.value) {
        activeSubscriptionIntervalId.value = intervalConfigurations.value?.find(
            (configuration: SubscriptionConfiguration) => configuration.productId === product.value?.id
        )?.intervalConfigurationId;
    }

    const activeSubscriptionOption = computed(() => {
        // @ts-ignore extension
        return intervalConfigurations.value?.find(
            (configuration: SubscriptionConfiguration) =>
                configuration.intervalConfigurationId === activeSubscriptionIntervalId.value
        );
    });
    const subscriptionTotalPrice = computed(() => {
        return activeSubscriptionOption.value?.subscriptionPrice?.totalPrice;
    });

    const subscriptionListPrice = computed(() => {
        return activeSubscriptionOption.value?.subscriptionPrice?.listPrice?.price;
    });
    const addSubscriptionToCart = async () => {
        try {
            const { data } = await invokePost(
                {
                    address: `/store-api/checkout/cart/line-item`,
                    payload: {
                        items: [{ referencedId: activeSubscriptionIntervalId.value, type: 'product_subscription' }],
                    },
                },
                apiInstance
            );
            await refreshCart(data as Cart);
            return data as Cart;
        } catch (e) {
            console.error('SwProductDetails:addSubscriptionToCart', e);
        }
    };
    watch(product, () => {
        if (!subscriptionChooserActive.value) {
            activeSubscriptionIntervalId.value =
                intervalConfigurations.value?.find(
                    (configuration: SubscriptionConfiguration) => configuration?.productId === product?.value?.id
                )?.intervalConfigurationId ?? intervalConfigurations.value?.[0]?.intervalConfigurationId;
        }
    });

    return {
        hasSubscriptionConfiguration,
        subscriptionChooserActive,
        subscriptionChooserOptions,
        activeSubscriptionOption,
        activeSubscriptionIntervalId,
        subscriptionTotalPrice,
        subscriptionListPrice,
        addSubscriptionToCart,
        intervalConfigurations,
    };
}
