<template>
    <div>
        <h4 class="font-medium mb-2">
            <CommonLabel path="BWF/shop/account/subscriptions/contract/nextDeliveryLabel"></CommonLabel>
        </h4>
        <div class="flex flex-col">
            <CommonLabel
                class="text-medium-grey"
                path="BWF/shop/account/subscriptions/contract/nextExecutionDate"
            ></CommonLabel>
            <FormattedDate v-if="nextExecutionDate" :date="nextExecutionDate" pattern="dddd YY.MM.DD"></FormattedDate>

            <CommonLabel
                class="text-medium-grey"
                path="BWF/shop/account/subscriptions/contract/costLabel"
            ></CommonLabel>

            <SharedPrice v-if="subscriptionAmountTotal" :value="subscriptionAmountTotal"></SharedPrice>

            <CommonLabel
                class="font-medium mt-4"
                path="BWF/shop/account/subscriptions/contract/deliveryIntervalLabel"
            ></CommonLabel>
            <p>
                <CommonLabel path="BWF/shop/account/subscriptions/contract/every"></CommonLabel
                ><span>{{ ` ${contract.interval} ${intervalUnitLabel}` }}</span>
            </p>
        </div>
    </div>
</template>

<script setup>
import CommonLabel from '~/templates/components/CommonLabel.vue';
import FormattedDate from '~/templates/elements/FormattedDate.vue';

const { getLabels } = useCommonLabels();
const props = defineProps(['contract']);

const intervalLabels = getLabels('BWF/shop/account/subscriptions/contract', ['days', 'weeks', 'months', 'years']);

const nextExecutionDate = computed(() => {
    return props.contract?.nextExecutionDate;
});

const intervalUnitLabel = intervalLabels[`${props.contract.intervalUnit}`];

const subscriptionAmountTotal = computed(() => {
    return props.contract?.webContractOrders[0].order.amountTotal;
});
</script>
